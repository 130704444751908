export function GetYearsArry(yearStart:number, yerarsLess: number) {
    const array = []
    for (let i = 0; i < yerarsLess; i++) {
      array.push(yearStart - i)
    }    
    return array;
}

export function StringisNullOrEmpity(str:string | null | undefined) {  
  if (str == undefined  || str == null || str == '' || str.replace(/\s/g, '').length < 1) {
      return true
  }
  else{
      return false
  }
}

export function ApenasNumber(e: string) {
  let value = e;
  value = value.replace(/\D/g, "");
  e = value;
  return e;
}