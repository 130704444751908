import React, { useState } from "react";
import { ErrorContext } from "../../../context/ErrorContext";
import ErrorModal from "../modalError/modalError";

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<any>("");

  const showError = (message: string) => {
    console.log(message,'message')
    setError(message);
  };



  return (
    <ErrorContext.Provider value={{ showError }}>
      {children}
      {error && <ErrorModal message={error} setError={setError}/>}
    </ErrorContext.Provider>
  );
};

export default ErrorBoundary;