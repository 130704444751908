import axios from "axios";
import secureLocalStorage from "react-secure-storage";
 
const Axios = axios.create({
  baseURL: "https://0h4sssk2fb.execute-api.us-east-1.amazonaws.com/prod",
   // baseURL: "http://localhost:3000",
  // baseURL: https://0h4sssk2fb.execute-api.us-east-1.amazonaws.com/prod,
});
 
Axios.interceptors.request.use(async (config) => {
  try {
    const token = secureLocalStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (err) {
    console.error("Error while setting Authorization header:", err);
  }
  return config;
});
 
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error("HTTP Error:", error.response.status, error.response.data);
    } else if (error.request) {
      console.error("Network Error:", error.request);
    } else {
      console.error("Unexpected Error:", error.message);
    }
    return Promise.reject(error);
  }
);
 
export default Axios;