import React, { useState, useEffect, useRef } from "react";
import "./SelectComparativo.css";

interface Option {
  value: string;
  fornecedorUnidadeDescricao: string;
  fornecedorDescricao: string;
}

interface Props {
  label: string;
  options: any;
  setSelectedOptions: any;
  selectedOptions: any
}

const Select: React.FC<Props> = ({ label, options, selectedOptions, setSelectedOptions }) => {

  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen((prevState) => !prevState);

  const handleOptionClick = (option: Option) => {

    if(selectedOptions.length === 10) {
        return;
    }

    if (selectedOptions.includes(option)) {
      return;
    }
    setSelectedOptions([...selectedOptions, option]);
  };

  const handleSelectedOptionRemove = (option: Option) => {
    setSelectedOptions(selectedOptions.filter((o: any) => o !== option));
    setIsOpen(false);
  };

  const getAvailableOptions = (): Option[] => {
    return options.filter((option: any) => !selectedOptions.includes(option));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="select-container" ref={selectRef}>
      <div className="title-select">{label}</div>
      <div className="select" onClick={toggleDropdown}>
        {selectedOptions.length === 0 ? (
          "Selecione uma opção"
        ) : (
          selectedOptions.map((option: any) => (
            <div key={option.value} className="selected-value">
              {`${option.fornecedorDescricao} - ${option.fornecedorUnidadeDescricao}`}
              <button
                className="remove-selected-value"
                onClick={(e) => {
                    e.stopPropagation();
                    handleSelectedOptionRemove(option)
                }}
              >
                X
              </button>
            </div>
          ))
        )}
        <div className="caret"></div>
      </div>
      {isOpen && (
        <>
          <div className="backdrop" onClick={toggleDropdown}></div>
          <div className="options-container" style={{ height: '20vw', overflowY: 'auto' }}>
            {getAvailableOptions().map((option) => (
              <div
                key={option.value}
                className={`option ${
                  selectedOptions.includes(option) ? "selected" : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {`${option.fornecedorDescricao} - ${option.fornecedorUnidadeDescricao}`}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Select;
