import React, { useState, useEffect, useRef } from "react";
import {
  GetUnidadeUsers,
  PatchUserUnidade,
  PostUsersUnidade,
} from "../../../../data/services";
import "./SelectUnidades.css";

interface Option {
  value: string;
  unidadeDescricao: string;
  idUsuario: any;
  idUnidade: any;
  tipoAcesso: any;
  idUsuariosUnidades: any;
  fornecedorDescricao: string;
  fornecedorUnidadeDescricao: string;
  idFornecedorUnidade: any;
  idFornecedor: any;
}

interface Props {
  label: string;
  options: any;
  setSelectedOptions: any;
  selectedOptions: any;
  id: any;
  value: any;
}

const Select: React.FC<Props> = ({
  label,
  options,
  selectedOptions,
  setSelectedOptions,
  id,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen((prevState) => !prevState);

  const handleOptionClick = (option: Option) => {
    if (selectedOptions.includes(option)) {
      return;
    }

    option.idUsuario = id;

    const Object = {
      idUsuario: option.idUsuario,
      idUnidade: option.idUnidade ?? option.idFornecedorUnidade,
      tipoAcesso: option.tipoAcesso,
    };

    PostUsersUnidade(Object);

    setSelectedOptions([...selectedOptions, option]);
  };

  const handleSelectedOptionRemove = async (option: Option) => {
    setSelectedOptions(selectedOptions.filter((o: any) => o !== option));
    if (value.tipo === "FORN") {
      const Object = {
        deleted: 1,
        idUsuario: option.idUsuario,
        idUnidade: option.idUnidade,
        tipoAcesso: option.tipoAcesso,
      };

      PatchUserUnidade(Object, option.idUsuariosUnidades);
      setIsOpen(false);
    } else {
      const Object = {
        deleted: 1,
        idUsuario: option.idUsuario,
        idUnidade: option.idUnidade,
        tipoAcesso: option.tipoAcesso,
      };

      PatchUserUnidade(Object, option.idUsuariosUnidades);
      setIsOpen(false);
    }
  };

  const getAvailableOptions = (): Option[] => {
    return options.filter((option: any) => !selectedOptions.includes(option));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="select-container" ref={selectRef}>
      <div className="title-select" style={{ color: "#66afe9", fontSize: 15 }}>
        {label}
      </div>
      <div className="select" onClick={toggleDropdown}>
        {selectedOptions.length === 0
          ? "Selecione uma opção"
          : selectedOptions.map((option: any) => (
              <div key={option.value} className="selected-value">
                {value.tipo === "FORN"
                  ? !option.fornecedorUnidadeDescricao
                    ? option.fornecedorDescricao
                    : `${option.fornecedorDescricao} - ${option.fornecedorUnidadeDescricao}`
                  : option.unidadeDescricao}
                <button
                  className="remove-selected-value"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectedOptionRemove(option);
                  }}
                >
                  X
                </button>
              </div>
            ))}
        <div className="caret"></div>
      </div>
      {isOpen && (
        <>
          <div className="backdrop" onClick={toggleDropdown}></div>
          <div className="options-container">
            {getAvailableOptions().map((option) => (
              <div
                key={option.value}
                className={`option ${
                  selectedOptions.includes(option) ? "selected" : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {value.tipo === "FORN"
                  ? `${option.fornecedorDescricao} - ${option.fornecedorUnidadeDescricao}`
                  : option.unidadeDescricao}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Select;
