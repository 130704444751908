import React, { useState, useEffect } from "react";
import "../../ui/components/login/login.css";
import { AuthLogin, GetNewPassword, GetUsers } from "../../data/services";
import secureLocalStorage from "react-secure-storage";
import {
  ContainerRequisitos,
  IconVisible,
  InputSenha,
} from "../login/senha.styles";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Alterar_Senha = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [lastPassword, setLastPassword] = useState<string>("");
  const [res, setRes] = useState<any>();
  const [error, setError] = useState<any>(false);
  const [success, setSuccess] = useState<any>(false);

  const idUsuario: any = secureLocalStorage.getItem("idUsuario");

  const [senhaValida, setSenhaValida] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [possuiMinimoCaracteres, setPossuiMinimoCaracteres] = useState(false);
  const [possuiNumero, setPossuiNumero] = useState(false);
  const [possuiMinuscula, setPossuiMinuscula] = useState(false);
  const [possuiMaiuscula, setPossuiMaiuscula] = useState(false);

  const NewPassword = (lastPassword: any, newPassword: any, e: any) => {
    e.preventDefault();
    GetNewPassword(idUsuario, lastPassword, newPassword, setRes);
  };

  useEffect(() => {
    if (res?.data?.mensagem) {
      if (
        res?.data?.mensagem === "Email não existe no cadastro Notifier" ||
        res?.data?.mensagem === "Erro ao logar usuário interno"
      ) {
        setError({
          error: true,
          message: "Senha incorreta. Por favor, tente novamente.",
        });
      }
      if (res?.status === 502) {
        secureLocalStorage.clear()        
        setError({
          error: true,
          message:
            "Ocorreu um problema ao realizar o login. Atualize a página e tente novamente!",
        });
      }
    }
  }, [res]);

  useEffect(() => {
    // const regexLetraMaiuscula = /[A-Z]/.test(senhaValida); //caso futuramente precise

    setPossuiMinimoCaracteres(/.{8,}/.test(newPassword));
    setPossuiNumero(/\d/.test(newPassword));
    setPossuiMinuscula(/[a-z]/.test(newPassword));
    setPossuiMaiuscula(/[A-Z]/.test(newPassword));

    if (
      possuiMinimoCaracteres &&
      possuiNumero &&
      possuiMinuscula &&
      possuiMaiuscula
    ) {
      return setSenhaValida(true);
    } else {
      return setSenhaValida(false);
    }
  }, [
    possuiMinimoCaracteres,
    possuiNumero,
    possuiMinuscula,
    possuiMaiuscula,
    newPassword
  ]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <section
          style={{
            textAlign: "center",
            width: "300px",
            boxShadow: "0 0 10px 0 rgb(0 0 0 / 10%)",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          <div className="content-logo">
            <img
              src="/assets/img/logo_notifier.png"
              className="logo-notify"
              alt="logo"
            />
          </div>
          <form
            onSubmit={(e) => NewPassword(lastPassword, newPassword, e)}
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <ContainerRequisitos>
              <h1
                style={{
                  color: "grey",
                  fontSize: 15,
                }}
              >
                Digite a sua senha atual:
              </h1>
              <InputSenha>
                <input
                  required
                  type={showPassword ? "text" : "password"}
                  className="input-login"
                  placeholder="Senha atual"
                  onChange={(e) => {
                    setLastPassword(e.target.value);
                  }}
                />

                <IconVisible onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <AiFillEyeInvisible size={20} />
                  ) : (
                    <AiFillEye size={20} />
                  )}
                </IconVisible>
              </InputSenha>

              <h1
                style={{
                  color: "grey",
                  fontSize: 16,
                }}
              >
                Digite uma nova senha com esses requisitos:
              </h1>
              <ul style={{ listStyleType: "disc", textAlign: "left" }}>
                <div
                  style={{ color: possuiMinimoCaracteres ? "green" : "red" }}
                >
                  <li>8 caracteres</li>
                </div>
                <div style={{ color: possuiNumero ? "green" : "red" }}>
                  <li>1 número</li>
                </div>
                <div style={{ color: possuiMinuscula ? "green" : "red" }}>
                  <li>1 letra minúscula</li>
                </div>
                <div style={{ color: possuiMaiuscula ? "green" : "red" }}>
                  <li>1 letra maiuscula</li>
                </div>
              </ul>
            </ContainerRequisitos>

            <InputSenha>
              <input
                required
                type={showPassword ? "text" : "password"}
                className="input-login"
                placeholder="Nova senha"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
              <IconVisible onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? (
                  <AiFillEyeInvisible size={20} />
                ) : (
                  <AiFillEye size={20} />
                )}
              </IconVisible>
            </InputSenha>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <input
                type="submit"
                className="btn-log"                
                disabled={!senhaValida}
                    style={{
                      width: 300, marginRight: 5, color: "white",
                      opacity: !senhaValida ? 0.4 : 1, cursor: !senhaValida ? "not-allowed" : 'pointer', 
                    }}
                
                value="Enviar"
              />
            </div>
            {error.error && (
              <div className="alert alert-danger" role="alert">
                {error.message}
              </div>
            )}
            {success && (
              <div className="alert alert-success" role="alert">
                {success}
              </div>
            )}
          </form>

          <div className="content-logo-petropolis">
            <img
              className="logo-petropolis"
              src="/assets/img/logo-petropolis.png"
              alt="logo"
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Alterar_Senha;
