import React, { useState, useEffect, useRef } from "react";
import { PatchProduct, PostProduct, } from "../../../../data/services";
import "./SelectProduct.css";

interface Option {
  value: string;
  tipoEmbalagem: string;
  idUsuario: any;
  idTipoEmbalagem: any;
  tipoAcesso: any;
  idProduto: any;
}

interface Props {
  label: string;
  options: any;
  setSelectedOptions: any;
  selectedOptions: any;
  id: any;
}

const SelectProduct: React.FC<Props> = ({
  label,
  options,
  selectedOptions,
  setSelectedOptions,
  id,
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen((prevState) => !prevState);

  const handleOptionClick = (option: Option) => {
    if (selectedOptions.includes(option)) {
      return;
    }
    setSelectedOptions([...selectedOptions, option]);
    
    // option.idProduto = id;
    // option.produto = name;

    // const Object = {
    //   produto: option.produto,
    //   idEmbalagem: option.idTipoEmbalagem,
    //   material: option.tipoEmbalagem
    // };

    // PostProduct(Object);

  };

  const handleSelectedOptionRemove = (option: Option) => {
    setSelectedOptions(selectedOptions.filter((o: any) => o !== option));
    setIsOpen(false);

    // const Object = {
    //   ...option, 
    //   deleted: 1,
    // };

    // console.log("option.idProduto", option.idProduto);
    // PatchProduct(option.idProduto, Object);
  };

  const getAvailableOptions = (): Option[] => {
    return options.filter((option: any) => !selectedOptions.includes(option));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="select-container" ref={selectRef}>
      <div className="title-select" style={{ color: "#66afe9", fontSize: 15 }}>
        {label}
      </div>
      <div className="select" onClick={toggleDropdown}>

        {/* não ta funcionando, selectedOptions tá chegando vazio */}
        {selectedOptions.length === 0
          ? "Selecione uma opção"
          : selectedOptions.map((option: any) => (
              <div key={option.value} className="selected-value">
                {option.tipoEmbalagem ?? "~~"}
                <button
                  className="remove-selected-value"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectedOptionRemove(option);
                  }}
                >
                  X
                </button>
              </div>
            ))}
        <div className="caret"></div>
      </div>
      {isOpen && (
        <>
          <div className="backdrop" onClick={toggleDropdown}></div>
          <div className="options-container">
            {getAvailableOptions().map((option) => (
              <div
                key={option.value}
                className={`option ${
                  selectedOptions.includes(option) ? "selected" : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option.tipoEmbalagem ?? "~~"}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SelectProduct;
