import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Container,
  ContainerRequisitos,
  ContainerSenha,
  IconVisible,
  InputSenha
} from "./senha.styles";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";

interface SenhaProps {
  validaSenha: Dispatch<SetStateAction<boolean>>;
  senhaAtual: Dispatch<SetStateAction<any>>;
}

const NewSenhaComponent: React.FC<SenhaProps> = ({
  validaSenha,
  senhaAtual,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState({
    password: "",
    confirmPassword: "",
    openModalNewPassword: false,
  });

  const [possuiMinimoCaracteres, setPossuiMinimoCaracteres] = useState(false);
  const [possuiNumero, setPossuiNumero] = useState(false);
  const [possuiMinuscula, setPossuiMinuscula] = useState(false);
  const [possuiMaiuscula, setPossuiMaiuscula] = useState(false);
  const [senhasIguais, setSenhasIguais] = useState(false);

  useEffect(() => {
    // const regexLetraMaiuscula = /[A-Z]/.test(senhaValida); //caso futuramente precise

    setPossuiMinimoCaracteres(/.{8,}/.test(newPassword.password));
    setPossuiNumero(/\d/.test(newPassword.password));
    setPossuiMinuscula(/[a-z]/.test(newPassword.password));
    setPossuiMaiuscula(/[A-Z]/.test(newPassword.password));
    setSenhasIguais(
      newPassword.password === newPassword.confirmPassword &&
        newPassword.password !== ""
    );

    if (
      possuiMinimoCaracteres &&
      possuiNumero &&
      possuiMinuscula &&
      possuiMaiuscula &&
      senhasIguais
    ) {
      senhaAtual(newPassword);
      return validaSenha(true);
    } else {
      senhaAtual(newPassword);
      return validaSenha(false);
    }
  }, [
    possuiMinimoCaracteres,
    possuiNumero,
    possuiMinuscula,
    possuiMaiuscula,
    senhasIguais,
    newPassword,
  ]);

  return (
    <Container>
      <ContainerSenha>
        <ContainerRequisitos>
          <ul style={{ listStyleType: "disc", textAlign: "left" }}>
            <div style={{ color: possuiMinimoCaracteres ? "green" : "red" }}>
              <li>8 caracteres</li>
            </div>
            <div style={{ color: possuiNumero ? "green" : "red" }}>
              <li>1 número</li>
            </div>
            <div style={{ color: possuiMinuscula ? "green" : "red" }}>
              <li>1 letra minúscula</li>
            </div>
            <div style={{ color: possuiMaiuscula ? "green" : "red" }}>
              <li>1 letra maiuscula</li>
            </div>
            <div style={{ color: senhasIguais ? "green" : "red" }}>
              <li>Senhas Iguais</li>
            </div>
          </ul>
        </ContainerRequisitos>

        <InputSenha>
          <input
            type={showPassword ? "text" : "password"}
            className="input-login"
            placeholder="senha"
            value={newPassword.password}
            onChange={(e) => {
              setNewPassword((prevState: any) => ({
                ...prevState,
                password: e.target.value,
              }));
            }}
          />
          <IconVisible onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <AiFillEyeInvisible size={20} />
            ) : (
              <AiFillEye size={20} />
            )}
          </IconVisible>
        </InputSenha>

        <InputSenha>
          <input
            type={showPassword ? "text" : "password"}
            className="input-login"
            placeholder="confirmar senha"
            value={newPassword.confirmPassword}
            onChange={(e) => {
              setNewPassword((prevState: any) => ({
                ...prevState,
                confirmPassword: e.target.value,
              }));
            }}
          />
          <IconVisible onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <AiFillEyeInvisible size={20} />
            ) : (
              <AiFillEye size={20} />
            )}
          </IconVisible>
        </InputSenha>
      </ContainerSenha>
    </Container>
  );
};

export default NewSenhaComponent;
