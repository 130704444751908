import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Container,
  ContainerRequisitos,
  ContainerSenha,
  IconVisible,
  InputSenha,
  StyledInputContainer,
} from "./senha.styles";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";

interface SenhaProps {
  validaSenha: Dispatch<SetStateAction<boolean>>;
  senhaAtual: Dispatch<SetStateAction<any>>;
}

const SenhaComponent: React.FC<SenhaProps> = ({ validaSenha, senhaAtual }) => {
  const [senhaValida, setSenhaValida] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [possuiMinimoCaracteres, setPossuiMinimoCaracteres] = useState(false);
  const [possuiNumero, setPossuiNumero] = useState(false);
  const [possuiMinuscula, setPossuiMinuscula] = useState(false);
  const [possuiMaiuscula, setPossuiMaiuscula] = useState(false);

  useEffect(() => {
    // const regexLetraMaiuscula = /[A-Z]/.test(senhaValida); //caso futuramente precise

    setPossuiMinimoCaracteres(/.{8,}/.test(senhaValida));
    setPossuiNumero(/\d/.test(senhaValida));
    setPossuiMinuscula(/[a-z]/.test(senhaValida));
    setPossuiMaiuscula(/[A-Z]/.test(senhaValida));

    if (
      possuiMinimoCaracteres &&
      possuiNumero &&
      possuiMinuscula &&
      possuiMaiuscula
    ) {
      senhaAtual(senhaValida);
      return validaSenha(true);
    } else {
      senhaAtual(senhaValida);
      return validaSenha(false);
    }
  }, [
    possuiMinimoCaracteres,
    possuiNumero,
    possuiMinuscula,
    possuiMaiuscula,
    senhaValida,
  ]);

  return (
    <Container>
      <ContainerSenha>
        <ContainerRequisitos>
          <h1
            style={{
              color: "grey",
              fontSize: 16
            }}
          >
            Digite uma nova senha com esses requisitos:
          </h1>
          <ul style={{ listStyleType: "disc", textAlign: "left" }}>
            <StyledInputContainer />
            <div style={{ color: possuiMinimoCaracteres ? "green" : "red" }}>
              <li>8 caracteres</li>
            </div>
            <div style={{ color: possuiNumero ? "green" : "red" }}>
              <li>1 número</li>
            </div>
            <div style={{ color: possuiMinuscula ? "green" : "red" }}>
              <li>1 letra minúscula</li>
            </div>
            <div style={{ color: possuiMaiuscula ? "green" : "red" }}>
              <li>1 letra maiuscula</li>
            </div>
          </ul>
        </ContainerRequisitos>

        <InputSenha>
          <input
            required
            type={showPassword ? "text" : "password"}
            className="input-login"
            placeholder="senha"
            onChange={(e) => {
              setSenhaValida(e.target.value);
            }}
          />
          <IconVisible onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
          </IconVisible>
        </InputSenha>
      </ContainerSenha>
    </Container>
  );
};

export default SenhaComponent;
