import React, { useEffect } from "react";
import "../../ui/components/comparativo/comparativo.css";
import { BsDownload, BsFiletypePdf, BsFiletypePng } from "react-icons/bs";
import BarChat from "../../ui/components/grafico";
import { IoIosSpeedometer } from "react-icons/io";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { GetYearsArry } from "../../helpers/helpers";
import { useAuth } from "../../context/auth";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);
const RankingPage = () => {
    const { user: { profile } } = useAuth()

    if (profile === "usuarioFornecedor") window.location.href = "/home";

    const [open, setOpen] = React.useState<boolean>(false);
    const [backgroundColor, setBackgroundColor] = React.useState([
        { color: "rgb(90 86 14 / 80%)" },
        { color: "rgb(121 116 42 / 80%)" },
        { color: "rgb(97 106 0 / 80%)" },
        { color: "rgb(177 121 0 / 80%)" },
        { color: "rgb(164 112 0 / 80%)" },
        { color: "brown" },
        { color: "rgb(122 106 0 / 80%)" },
        { color: "rgb(68 61 17 / 80%)" },
        { color: "rgb(114 100 17 / 80%)" },
        { color: "rgba(163, 142, 23, 0.8)" },
    ]);
    const [anosPesquisa, setAnosPesquisa] = React.useState<number[]>([])
    const [data, setData] = React.useState<any>([]);
    const [FornMock, setFornMock] = React.useState([
        {
            id: 1,
            name: "All Plastic",
            pqmf: 3.4,
            embalagem: 'plastic'
        },
        {
            id: 2,
            name: "Papelex",
            pqmf: 4.1,
            embalagem: 'paper'
        },
        {
            id: 3,
            name: "Aluminios Espelhados",
            pqmf: 0.8,
            embalagem: 'alumin'
        },
        {
            id: 4,
            name: "Office Paper",
            pqmf: 1.9,
            embalagem: 'paper'
        },
        {
            id: 5,
            name: "Durabilidade total",
            pqmf: 4.7,
            embalagem: 'plastic'
        },
        {
            id: 6,
            name: "Metal Super",
            pqmf: 8.1,
            embalagem: 'metal'
        },
        {
            id: 7,
            name: "Folhas boas",
            pqmf: 6.8,
            embalagem: 'paper'
        },
        {
            id: 8,
            name: "Uklo Alumin",
            pqmf: 7.1,
            embalagem: 'alumin'
        },
        {
            id: 9,
            name: "Fabris Metal",
            pqmf: 10,
            embalagem: 'metal'
        },
        {
            id: 10,
            name: "Diferenciais Alumin",
            pqmf: 7.5,
            embalagem: 'alumin'
        },

    ]);

    FornMock.sort((a, b) => (a.pqmf < b.pqmf) ? 1 : -1)

    // agrupar select com tipos de embalagens
    // const agroup = _.groupBy(FornMock, "embalagem")

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        indexAxis: 'y',
        scales: {
            y: {
                beginAtZero: false
            },
        }
    };
    const [userData, setUserData] = React.useState<any>({
        labels:
            FornMock.map((item: any) => item.name),
        datasets: [
            {
                type: "bar" as const,
                data: FornMock.map((item: any) => item.pqmf),
                label: 'PQMF',
                backgroundColor: backgroundColor.map((i) => i.color),
            },
        ]
    });

    const filterEmbalagem = (item: any) => {

        const newForn = FornMock.filter((i: any) => i.embalagem === item.embalagem)

        setUserData({
            labels:
                newForn.map((i: any) => i.name),
            datasets: [
                {
                    type: "bar" as const,
                    data: newForn.map((i: any) => i.pqmf),
                    label: 'PQMF',
                    backgroundColor: backgroundColor.map((i) => i.color),
                },
            ]
        })
    }

    const exportPDF = () => {
        console.log("exportPDF", exportPDF);
    }

    const exportPNG = () => {
        console.log("exportPNG", exportPNG);
    }

    useEffect(() => {
        setAnosPesquisa(GetYearsArry(new Date().getFullYear(), 7));
    }, [data]);

    return (
        <div>
            <div className="header">
                <h1>PQMF - Ranking</h1>
            </div>
            <div className="info-compare">
                <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "40%", marginRight: "20px" }} >
                        <label style={{ marginRight: 15, color: "#555555", width: "100%" }}>Ano</label>
                        <select
                            id="statusSelect"
                            name=""
                            className="select-defect"
                            style={{ outline: "none", width: "100%" }}
                        >
                            {anosPesquisa.map((item: any) => {
                                return (
                                    <option value={item}>
                                        {item}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }} >
                        <label style={{ marginRight: 15, color: "#555555", width: "100%" }} >
                            Embalagens
                        </label>
                        <select
                            id=""
                            name=""
                            className="select-defect"
                            style={{ outline: "none", width: "80%" }}
                        >
                            {
                                FornMock.map((item: any) =>
                                    <option
                                        value={item.embalagem}
                                        onClick={() => {
                                            filterEmbalagem(item)
                                        }}
                                        key={item.id}
                                    >
                                        {item.embalagem}
                                    </option>
                                )
                            }


                        </select>
                    </div>

                </div>

                <div style={{ display: "flex", height: 40 }}>
                    <button
                        className="button-defect-search"
                        onClick={() => setOpen(!open)}
                    >
                        <BsDownload style={{ marginRight: 10 }} />
                        Exportar dados
                    </button>
                    {
                        open &&
                        <div>
                            <button
                                className="public-button"
                                onClick={() => exportPDF()}>
                                <BsFiletypePdf style={{ marginRight: 10 }} /> PDF
                            </button>
                            <button
                                className="delete-button"
                                onClick={() => exportPNG()}>
                                <BsFiletypePng style={{ marginRight: 10 }} /> PNG
                            </button>
                        </div>
                    }
                </div>

            </div>
            <div
                style={{
                    width: "100%",
                    borderTop: "1px solid rgba(34,36,38,.15)",
                    marginTop: 12,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <h1
                        style={{ fontSize: 14, marginBottom: 15 }}
                        className="title-graphic"
                    >
                        <IoIosSpeedometer
                            color="rgba(163, 142, 23, 0.8)"
                            size={20}
                            style={{ marginRight: 20 }}
                        />
                        Ranking anual Fornecedores
                    </h1>
                    <div style={{ width: "99.5%" }}>
                        <BarChat data={userData} options={options} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RankingPage;
