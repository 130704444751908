import React from 'react';

interface DivErrorProps {
  texto: string;
}

const DivError: React.FC<DivErrorProps> = ({ texto }) => {
  return (
    <div>
      <p style={{ color: "red", fontSize: '12px' }}>{texto}</p>
    </div>
  );
};

export default DivError;
