import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { captchaKeys } from './shared/enums/captchaKeys'

import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <GoogleReCaptchaProvider
    language={'pt-BR'}
		reCaptchaKey={captchaKeys.USERS_CAPTCHA_KEY}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleReCaptchaProvider>
);
