import React, { useEffect, useState } from "react";
import {
  PatchFeriado,
  PatchSuppliers,
  PatchDefects,
  PatchUser,
  PostFeriado,
  PostDefect,
  PostSuppliers,
  PostUser,
  PostFornecedorEmbalagem,
  PostFornecedorUnidade,
  PatchFornecedorUnidade,
  PatchFornecedorEmbalagem,
  PatchEmbalagensAdm,
  PostNewDefects,
  GetDefects,
  GetfornecedorUnidades,
  GetGpUnidades,
  PostEmbalagens,
  GetSuppliersByUnidadeId,
  DeleteFornecedorUnidades,
  PostProduct,
  PatchProduct,
  GetUnidadeUsers,
  PostCategoryEmbalagens,
  GetEmbalagemForCategory,
  PostBatchProducts,
} from "../../../../data/services";
import "./sigIn.css";
import { AiFillDelete } from "react-icons/ai";
import { MdArrowDropDown } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import Select from "../SelectUnidades/SelectUnidades";
import ErrorBoundary from "../../Error";
import { ErrorContext } from "../../../../context/ErrorContext";
import SelectProduct from "../SelectProduct/SelectProduct";
import {
  ApenasNumber,
  StringisNullOrEmpity,
} from "../../../../helpers/helpers";

interface Props {
  setOpen: (value: boolean) => void;
  info: any;
  method: any;
  type: string;
  embalagens?: any;
  defeitos?: any;
  setDefects?: any;
  users?: any;
  unidades?: any;
}

const SignIn = ({
  setOpen,
  info,
  method,
  type,
  embalagens,
  defeitos,
  unidades,
}: Props) => {
  const [val, setVal] = React.useState(info);
  const [unity, setUnity] = React.useState<any>(val.unidades ?? []);
  const [embalagem, setEmbalagem] = React.useState<any>(val.embalagens ?? []);
  const [OpenSelect, setOpenSelect] = React.useState(false);
  const [editUnity, setEditUnity] = React.useState<any>();
  const [modalText, setModalText] = React.useState<any>();
  const [newDefect, setNewDefect] = React.useState<any>(defeitos);
  const [newDefectOriginal, SetnewDefectOriginal] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<any>(false);
  const [error, setError] = React.useState<any>(false);
  const [dataGetUnidadeForn, setDataGetUnidadeForn] = React.useState<any>([]);
  const [allUnidades, setAllUnidades] = React.useState([]);
  const [allEmbalagens, setAllEmbalagens] = React.useState<any>([]);
  const [openConfirmModal, setOpenConfirmModal] = React.useState<any>(false);
  const [exclusionData, setExclusionData] = React.useState<any>();
  const [exclusionPackaging, setExclusionPackaging] = React.useState<any>();
  const AddUnit = React.useCallback((unidade: any) => {
    if (StringisNullOrEmpity(unidade[0].fornecedorUnidadeDescricao)) {
      alert("Descrição da unidade deve ser preenchida!!");
      return;
    } else {
      if (!unidade || unidade.length === 0) {
        return;
      }
      }

      unity.push(...unidade);

      setUnity(unity);

      if (type === "Fornecedores") {
        setVal((prev: any) => ({
          ...prev,
          unidades: unity,
        }));
        setTimeout(() => {
          PostFornecedorUnidade(unidade);
        }, 1000);
      }
  }, []);

  useEffect(() => {
    if (type === "Fornecedores") {
      setVal((prev: any) => ({
        ...prev,
        embalagens: embalagem,
      }));
    }
  }, [embalagem]);

  const Embalagem = React.useCallback((embalagem: any) => {
    if (!embalagem) {
      return;
    }
    setEmbalagem((prev: any) => [...prev, ...embalagem]);
  }, []);

  useEffect(() => {
    if (type === "Fornecedores") {
      GetSuppliersByUnidadeId(setDataGetUnidadeForn, val.idFornecedor);
    }
  }, [val.idFornecedor]);

  const validateDigitCPF = (arr: number[], position: number): boolean => {
    let factor: number;
    let arrayDigit: number;
    let sum = 0;

    if (position === 1) {
      factor = 10;
      arrayDigit = 9;
    } else {
      factor = 11;
      arrayDigit = 10;
    }

    for (let i = 0; i < arrayDigit; i += 1) {
      sum += arr[i] * factor;
      factor -= 1;
    }

    const division = Math.floor(sum % 11);
    let verifyingDigit = 0;

    if (division > 1) {
      verifyingDigit = 11 - division;
    }

    if (arr[arrayDigit] !== verifyingDigit) {
      return false;
    }

    return true;
  };

  const validateCPF = (cpf: unknown): boolean => {
    if (typeof cpf !== "string" && typeof cpf !== "number") {
      return false;
    }

    let filteredCPF = String(cpf);
    filteredCPF = filteredCPF.replace(/\.|-/g, "");

    if (filteredCPF.length !== 11) {
      return false;
    }

    const arrCPF: number[] = Array.from(filteredCPF, Number);

    const repeatedNumbers: boolean = arrCPF.every(
      (num, i, arr) => num === arr[0]
    );
    if (repeatedNumbers) {
      return false;
    }

    const firstDigit = validateDigitCPF(arrCPF, 1);
    const secondDigit = validateDigitCPF(arrCPF, 2);
    if (!firstDigit || !secondDigit) {
      return false;
    }

    return true;
  };

  const validateDigitCNPJ = (arr: number[], position: number): boolean => {
    let weights: number[];
    let arrayItems: number;
    let sum = 0;

    if (position === 1) {
      weights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      arrayItems = 12;
    } else {
      weights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      arrayItems = 13;
    }

    for (let i = 0; i < arrayItems; i += 1) {
      const calc = weights[i] * arr[i];
      sum += calc;
    }

    const division = Math.floor(sum % 11);
    let verifyingDigit = 0;

    if (division >= 2) {
      verifyingDigit = 11 - division;
    }

    if (arr[arrayItems] !== verifyingDigit) {
      return false;
    }

    return true;
  };

  const validateCNPJ = (cnpj: unknown): boolean => {
    if (typeof cnpj !== "string" && typeof cnpj !== "number") {
      return false;
    }

    let filteredCNPJ = String(cnpj);
    filteredCNPJ = filteredCNPJ.replace(/\.|-|\//g, "");

    if (filteredCNPJ.length !== 14) {
      return false;
    }

    const arrCNPJ: number[] = Array.from(filteredCNPJ, Number);

    const repeatedNumbers: boolean = arrCNPJ.every(
      (num, i, arr) => num === arr[0]
    );
    if (repeatedNumbers) {
      return false;
    }

    const firstDigit = validateDigitCNPJ(arrCNPJ, 1);
    const secondDigit = validateDigitCNPJ(arrCNPJ, 2);
    if (!firstDigit || !secondDigit) {
      return false;
    }

    return true;
  };

  const { showError } = React.useContext(ErrorContext);

  const [responseData, setResponseData] = useState(null);

  const Send = async (object: any, categorias?:any) => {

    try {

      if (method === "putch") {
        if (type === "Users") {
          if (!object.email.includes("@") || !object.email.includes(".com")) {
            alert("Por favor, digite um email válido: example@adress.com")
            return
          }
          PatchUser({
            id: object.idUsuario,
            value: object,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
  
        if (type === "Embalagens") {
          setLoading(true);
          PatchEmbalagensAdm(object.idTipoEmbalagem, object);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
  
        if (type === "Feriado") {
          const num = object.descricaoFeriado.length;
  
          if (StringisNullOrEmpity(object.descricaoFeriado)) {
            alert("Descrição do feriado não pode ser vazio!");
            return;
          } else if (object.dia == 0 || object.dia == undefined) {
            alert("Dia incorreto!");
            return;
          } else if (object.mes == 0 || object.dia == undefined) {
            alert("Mês incorreto!");
            return;
          } else if (num > 50) {
            alert("Descrição não pode ter mais que 50 caracteres!!");
            return;
          }
  
          PatchFeriado({
            id: object.idFeriado,
            value: object,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
  
        if (type === "Product") {
          PatchProduct(object.idProduto, object);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        if (type === "Fornecedores") {
          if (object.cpfCnpj.length <= 14) {
            if (validateCPF(object.cpfCnpj)) {
              PatchSuppliers({
                id: object.idFornecedor,
                value: object,
              });
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              return setError("Este CPF não é válido");
            }
          } else {
            if (validateCNPJ(object.cpfCnpj)) {
              PatchSuppliers({
                id: object.idFornecedor,
                value: object,
              });
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              return setError("Este CNPJ não é válido");
            }
          }
        }
  
        if (type === "Defeitos") {
          PatchDefects(object, object.idTipoDefeito);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else {
        if (type === "Users") {
         
            if (Object.keys(object).length > 0) {
              //validação de campos
              if (StringisNullOrEmpity(object.nomeUsuario)) {
                alert("É preciso digitar um nome de usuário!");
                return;
              } else if (
                StringisNullOrEmpity(object.email) ||
                !object.email.includes("@") ||
                object.email.length < 5 ||
                object.email.indexOf(" ") >= 0
              ) {
                alert("Email invalido!");
                return;
              } else if (
                (StringisNullOrEmpity(object.login) || object.login.length !== 8) &&
                object.tipo == "GP"
              ) {
                alert("Matrícula invalida deve conter 8 numeros!");
                return;
              } else if (
                (StringisNullOrEmpity(object.perfilAcesso) ||
                  object.perfilAcesso == "Selecione") &&
                object.tipo == "GP"
              ) {
                alert("É preciso selecionar um perfil de acesso!");
                return;
              }

            try {
              const response = await PostUser(object, showError);


              if (response && response.status >= 200 && response.status < 300) {
                  const responseData = response.data;      
                  setResponseData(responseData);           
                  alert('Usuário criado');      
                  setTimeout(() => {         
                    window.location.reload();       
                  }, 3000);

                } else {      
                  setResponseData(null);       
                  console.error('Falha na requisição. Status:', response?.status);
                  alert(`Erro inesperado ao criar usuário`)
                }
            } catch(error: any) {
              let errorMessage;

              if (error.response && error.response.status) {
                errorMessage = `HTTP Error: ${error.response.status}, ${error.response.data.mensagem}`
              } else {
                errorMessage = 'Erro inesperado'
              }
              console.error(`Erro em criar usuário: ${errorMessage}`);
              setResponseData(null);    
              alert(`Erro 543: ${errorMessage}`);   
            } 
          };
        }
      
        if (type === "Feriado") {
          const num = object.descricaoFeriado.length;
          if (StringisNullOrEmpity(object.descricaoFeriado)) {
            alert("Descrição do feriado não pode ser vazio!");
            return;
          } else if (object.dia == 0 || object.dia == undefined) {
            alert("Dia incorreto!");
            return;
          } else if (object.mes == 0 || object.dia == undefined) {
            alert("Mês incorreto!");
            return;
          } else if (num > 50) {
            alert("Descrição não pode ter mais que 50 caracteres!!");
            return;
          }
  
          if (Object.keys(object).length > 0) {
            if (object.dia === "0" || object.mes === "0") {
              return;
            }
  
            PostFeriado(object);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            return;
          }
        }
        if (type === "Product") {
          if (Object.keys(object).length > 0) {
            if (!object.produto) {
              return setError({ produto: "Preencha o produto corretamente" });
            }
            for (const i of allEmbalagens) {

              const embalagensParaVincular = await GetEmbalagemForCategory(i.idTipoEmbalagem); 

              PostBatchProducts(object.produto, embalagensParaVincular);
            }
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            return setError({
              produto: "Preencha o produto corretamente",
              material: "Preencha o material corretamente",
            });
          }
        }
        if (type === "Defeitos") {
          if (Object.keys(object).length > 0) {
            PostDefect(object);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            return;
          }
        }
  
        if (type === "Fornecedores") {
          if (Object.keys(object).length > 0) {
            if (object.cpfCnpj.length <= 14) {
              if (validateCPF(object.cpfCnpj)) {
                PostSuppliers(object);
                setLoading(true);
  
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              } else {
                return setError("Este CPF não é válido");
              }
            } else {
              if (validateCNPJ(object.cpfCnpj)) {
                PostSuppliers(object);
                setLoading(true);
  
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              } else {
                return setError("Este CNPJ não é válido");
              }
            }
          }
        }
  
        if (type === "Embalagens") {
          if (Object.keys(object).length > 0) {
  
            
            setLoading(true);
  
            for (const i of categorias) {
              const Object = {
                idTipoEmbalagem: i.idTipoEmbalagem,
                idMaterialSap: object.idMaterialSap,
              };
              PostCategoryEmbalagens(Object)
            }
            
  
            let obj = {
              material: object.embalagem, 
              idMaterialSap: object.idMaterialSap
            }
  
            PostEmbalagens(obj)
  
            setLoading(false);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }else {
            return setError({
              embalagem: "Preencha o produto corretamente",
              idMaterialSap: "Preencha o material corretamente",
            });
          }
        }
      }

    } catch (e) {
      console.log("erro: ", e)
    }

   
  }

  const handleChangeEmbalagem = (obj: any, id: any) => {
    const Array = {
      idFornecedor: id,
      deleted: 0,
      idTipoDeEmbalagem: obj.idTipoEmbalagem
    };

    const allEmbalagens = val.embalagens?.map((item: any) => {
      return item;
    });

    const newArray = [...allEmbalagens, Array];

    setVal((prev: any) => ({
      ...prev,
      embalagens: newArray,
    }));

    PostFornecedorEmbalagem(Array);
  };

  const patchNewUnity = React.useCallback((item: any) => {
    PatchFornecedorUnidade(item, item.idFornecedorUnidade);
    setEditUnity(false);
    setModalText("Unidade editada com sucesso");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }, []);

  const patchNewPackage = React.useCallback((item: any, categoriasEscolhidads: any) => {
    Send(item, categoriasEscolhidads);
  }, []);

  const DeleteUnit = React.useCallback((item: any, data: any) => {
    if (data?.length > 0) {
      item.deleted = 1;

      const filter = data?.filter((dt: any) => {
        return (
          dt?.fornecedorUnidadeDescricao === item?.fornecedorUnidadeDescricao
        );
      });

      const newObj = {
        deleted: 1,
        fornecedorUnidadeDescricao: filter[0]?.fornecedorUnidadeDescricao,
        idFornecedor: filter[0]?.idFornecedor,
        idFornecedorUnidade: filter[0]?.idFornecedorUnidade,
      };

      DeleteFornecedorUnidades(newObj.idFornecedorUnidade);

      // GetSuppliersByUnidadeId(setDataGetUnidadeForn, val.idFornecedor);

      setEditUnity(false);
      setModalText("Unidade deletada com sucesso!");
      setTimeout(() => {
        setModalText(false);
      }, 3000);
    }
  }, []);

  const handleDeleteEmbalagemForn = React.useCallback((item: any) => {
    item.deleted = 1;

    PatchFornecedorEmbalagem(item.idFornecedorTipoEmbalagemFabricada);

    val.embalagens.filter((em: any) => {
      return em.deleted === 0;
    });

    setEditUnity(false);
    setModalText("Embalagem deletada com sucesso!");
    setTimeout(() => {
      setModalText(false);
    }, 3000);
  }, []);

  const DeleteUnitPost = (item: any) => {
    const filter = val.unidades.filter((un: any) => {
      return un !== item;
    });

    setUnity(filter);

    setEditUnity(false);
  };

  const DeleteEmbalagensPost = (item: any) => {
    const indice = val.embalagens.indexOf(item.idTipoEmbalagem);

    const newEmbalagens = val.embalagens.splice(indice, 1);
    setVal((prev: any) => ({
      ...prev,
      embalagens: newEmbalagens,
    }));
  };

  const handleAddDefect = (item: any) => {
    const object = {
      idTipoEmbalagem: val.idTipoEmbalagem,
      tipoDefeito: item.tipoDefeito,
      idTipoDefeito: item.idTipoDefeito,
      deleted: 0,
    };

    PostNewDefects(object);
    setLoading(true);
    setTimeout(() => {
      GetDefects(setNewDefect);
      setLoading(false);
      setOpenSelect(false);
    }, 3000);
  };

  let defectByEmbalagem =
    type === "Embalagens" &&
    newDefect?.filter((item: any) => {
      return item.idTipoEmbalagem == val.idTipoEmbalagem;
    });

  const HandleDeleteDefect = (item: any) => {
    item.idTipoEmbalagem = "0";
    PatchDefects(item, item.idTipoDefeito);
    setLoading(true);
    GetDefects(setNewDefect);
    setLoading(false);
    setOpenSelect(false);

    setModalText("Defeito deletado com sucesso!");
    setTimeout(() => {
      setModalText(false);
    }, 3000);
  };

  if (!val.idTipoEmbalagem) {
    defectByEmbalagem = [];
  }

  function randomNumberInterval(a: number, b: number) {
    return Math.floor(Math.random() * (b - a + 1)) + a;
  }

  function formatCpfCnpj(value: any) {
    value = value.replace(/\D/g, ""); // remove caracteres não numéricos
    if (value?.length === 11) {
      // formato CPF: 999.999.999-99
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (value?.length === 14) {
      // formato CNPJ: 99.999.999/9999-99
      value = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }
    return value;
  }

  function handleChange(event: any) {
    let newValue = formatCpfCnpj(event.target.value);
    if (method === "putch") {
      setVal((prev: any) => ({
        ...prev,
        cpfCnpj: newValue,
      }));
    } else {
      setVal((prev: any) => ({
        ...prev,
        cpfCnpj: newValue,
        idFornecedor: Math.floor(Math.random() * 10000),
      }));
    }
  }

  const selectRef = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setOpenSelect(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const filterUnits = unidades?.filter((item: any) => {
    return item.idUsuario === val?.idUsuario;
  });

  const [unidadesUser, setUnidadeUser] = React.useState<any>([]);

  useEffect(() => {
    SetnewDefectOriginal(newDefect);
    if (filterUnits?.length > 0) {
      setAllUnidades(filterUnits);
    }
  }, []);

  useEffect(() => {
    async function loadUnidades() {
      if (type === "Users") {
        if (info.tipo == "FORN") {
          const fornecedorUnidades = await GetUnidadeUsers();
          let unidadeFornecedor = new Array();
          fornecedorUnidades.map((item: any) => {
            unidadeFornecedor.push({
              fornecedorDescricao: item.fornecedorDescricao,
              fornecedorUnidadeDescricao: item.fornecedorUnidadeDescricao,
              idFornecedor: item.idFornecedor,
              idFornecedorUnidade: item.idFornecedorUnidade,
              deleted: item.deleted,
            });
          });
          setUnidadeUser(unidadeFornecedor);
        } else {
          const GpUnidades = await GetGpUnidades();
          let unidadeFornecedor = new Array();
          GpUnidades.map((item: any) => {
            unidadeFornecedor.push({
              fornecedorDescricao: null,
              idUnidade: item.idGpUnidade,
              idUsuario: null,
              idUsuariosUnidades: null,
              tipoAcesso: null,
              unidadeDescricao: item.gpUnidadeDescricao,
            });
          });
          setUnidadeUser(unidadeFornecedor);
        }
      }
    }
    loadUnidades();
  }, []);

  unidades = unidades.filter(function (obj: any) {
    return filterUnits.indexOf(obj) == -1 && obj.unidadeDescricao != null;
  });

  function filtrarDefeito(e: any) {
    const filter = newDefectOriginal.filter((a: any) =>
      a.tipoDefeito.toUpperCase().includes(e.toUpperCase())
    );
    setNewDefect(filter);
  }

  return (
    <ErrorBoundary>
      {!loading ? (
        <div className="Container">
          <div className="content-header-button-and-title m-2">
            <h2>
              {method === "post"
                ? type === "Users"
                  ? "Adicionar usuário"
                  : type === "Feriado"
                  ? "Adicionar feriado"
                  : type === "Fornecedores"
                  ? "Adicionar fornecedor"
                  : type === "Defeitos"
                  ? "Adicionar defeito"
                  : type === "Embalagens"
                  ? "Adicionar embalagem"
                  : type === "Product"
                  ? "Adicionar produto"
                  : ""
                : type === "Users"
                ? "Editar usuário"
                : type === "Feriado"
                ? "Editar feriado"
                : type === "Fornecedores"
                ? "Editar fornecedor"
                : type === "Defeitos"
                ? "Editar defeito"
                : type === "Embalagens"
                ? "Editar embalagem"
                : type === "Product"
                ? "Editar produto"
                : ""}
            </h2>
          </div>
          {type === "Users" ? (
            <>
              <div className="divInput">
                <h1
                  style={{ color: "#66afe9", fontSize: 16, fontWeight: "bold" }}
                >
                  Tipo de perfil
                </h1>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    style={{ margin: 0, marginRight: 5, width: 20 }}
                    type="checkbox"
                    checked={val?.tipo === "GP" ? true : false}
                    disabled={method === "putch" && true}
                    className="form-check-input"
                    value="GP"
                    onChange={(e) => {
                      setVal((prev: any) => ({
                        ...prev,
                        tipo: e.target.value,
                      }));
                    }}
                  />
                  <p style={{ margin: 0, fontWeight: "bold", color: "black" }}>
                    Colaborador
                  </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    style={{ margin: 0, marginRight: 5, width: 20 }}
                    type="checkbox"
                    checked={val?.tipo === "FORN" ? true : false}
                    className="form-check-input"
                    disabled={method === "putch" && true}
                    value="FORN"
                    onChange={(e) => {
                      setVal((prev: any) => ({
                        ...prev,
                        tipo: e.target.value,
                      }));
                    }}
                  />
                  <p style={{ margin: 0, fontWeight: "bold", color: "black" }}>
                    Fornecedor
                  </p>
                </div>
              </div>
              {val?.tipo === "GP" ? (
                <>
                  <div className="divInput">
                    <input
                      className="input-sign-in"
                      value={val?.nomeUsuario}
                      type="text"
                      maxLength={50}
                      onChange={(e) => {
                        setVal((prev: any) => ({
                          ...prev,
                          nomeUsuario: e.target.value,
                        }));
                      }}
                    />
                    <label>Nome de usuário</label>
                  </div>
                  {method === "putch" && (
                    <div className="divInput">
                      <Select
                        label="Unidades"
                        options={unidadesUser}
                        id={val.idUsuario}
                        setSelectedOptions={setAllUnidades}
                        selectedOptions={allUnidades}
                        value={val}
                      />
                    </div>
                  )}
                  <div className="divInput">
                    <input
                      type="text"
                      className="input-sign-in"
                      value={val?.email}
                      maxLength={100}
                      onChange={(e) => {
                        setVal((prev: any) => ({
                          ...prev,
                          email: e.target.value,
                        }));
                      }}
                    />
                    <label>E-mail</label>
                  </div>

                  <div className="divInput">
                    <input
                      className="input-sign-in"
                      value={val?.login}
                      maxLength={8}
                      onChange={(e) => {
                        setVal((prev: any) => ({
                          ...prev,
                          login: ApenasNumber(e.target.value),
                        }));
                      }}
                    />
                    <label style={{ marginBottom: error && 35 }}>
                      Matrícula
                    </label>
                    {error && (
                      <div className="cpf-cnpj-input__error-message">
                        {error}
                      </div>
                    )}
                  </div>

                  <div className="divInput">
                    <label
                      style={{
                        marginBottom: error ? 55 : 35,
                        color: "#66afe9",
                      }}
                    >
                      Perfil de acesso
                    </label>
                    <select
                      id="statusSelect"
                      className="select-defect"
                      onChange={(e) => {
                        setVal((prev: any) => ({
                          ...prev,
                          perfilAcesso: e.target.value ?? "Selecione",
                        }));
                      }}
                      value={val.perfilAcesso ?? "Selecione"}
                      style={{ outline: "none", width: "100%" }}
                    >
                      <option>Selecione</option>
                      <option value="usuarioColaborador">
                        Usuário Colaborador
                      </option>
                      <option value="adminUnidade">
                        Administrador da unidade
                      </option>
                      <option value="superAdmin">Administrador geral</option>
                    </select>
                    {error && (
                      <div className="cpf-cnpj-input__error-message">
                        {error}
                      </div>
                    )}
                  </div>
                </>
              ) : val?.tipo === "FORN" ? (
                <>
                  <div className="divInput">
                    <input
                      className="input-sign-in"
                      value={val?.nomeUsuario}
                      onChange={(e) => {
                        setVal((prev: any) => ({
                          ...prev,
                          nomeUsuario: e.target.value,
                        }));
                      }}
                    />
                    <label>Nome de usuário</label>
                  </div>
                  {method === "putch" && (
                    <div className="divInput">
                      <Select
                        label="Unidades"
                        options={unidadesUser}
                        id={val.idUsuario}
                        setSelectedOptions={setAllUnidades}
                        selectedOptions={allUnidades}
                        value={val}
                      />
                    </div>
                  )}
                  <div className="divInput">
                    <label
                      style={{
                        marginBottom: error ? 40 : 25,
                        color: "#66afe9",
                      }}
                    >
                      E-mail
                    </label>
                    <input
                      type="text"
                      className="input-sign-in"
                      value={val?.email}
                      onChange={(e) => {
                        setVal((prev: any) => ({
                          ...prev,
                          email: e.target.value,
                          login: e.target.value,
                          perfilAcesso: "usuarioFornecedor",
                        }));
                      }}
                    />
                    {error && (
                      <div className="cpf-cnpj-input__error-message">
                        {error}
                      </div>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              <div
                style={{
                  display: "flex",
                  width: "40%",
                  justifyContent: "space-around",
                }}
              >
                <div className="content-button-send">
                  <button className="button-send" onClick={() => Send(val)}>
                    ENVIAR
                  </button>
                </div>
                <div className="content-button-send">
                  <button
                    className="button-send"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </>
          ) : type === "Feriado" ? (
            <>
              <div className="divInput">
                <input
                  type="number"
                  className="input-sign-in"
                  value={val?.dia}
                  onChange={(e) => {
                    if (
                      Number(e.target.value) > 31 ||
                      Number(e.target.value) <= 0
                    ) {
                      e.target.value = "";
                      alert("Por favor, insira um número entre 1 e 31");
                    }
                    setVal((prev: any) => ({
                      ...prev,
                      dia: e.target.value,
                    }));
                  }}
                />
                <label style={{ marginBottom: error?.dia && 25 }}>Dia</label>
                {error?.dia && (
                  <div className="cpf-cnpj-input__error-message">
                    {error.dia}
                  </div>
                )}
              </div>
              <div className="divInput">
                <input
                  type="number"
                  className="input-sign-in"
                  value={val?.mes}
                  onChange={(e) => {
                    if (
                      Number(e.target.value) > 12 ||
                      Number(e.target.value) <= 0
                    ) {
                      e.target.value = "";
                      alert("Por favor, insira um número entre 1 e 12");
                    }
                    setVal((prev: any) => ({
                      ...prev,
                      mes: e.target.value,
                    }));
                  }}
                />
                <label>Mês</label>
              </div>
              <div className="divInput">
                <input
                  type="text"
                  className="input-sign-in"
                  maxLength={50}
                  value={val?.descricaoFeriado}
                  onChange={(e) => {
                    setVal((prev: any) => ({
                      ...prev,
                      descricaoFeriado: e.target.value,
                    }));
                  }}
                />
                <label>Descrição do feriado</label>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  justifyContent: "space-around",
                }}
              >
                <div className="content-button-send">
                  <button className="button-send" onClick={() => Send(val)}>
                    ENVIAR
                  </button>
                </div>
                <div className="content-button-send">
                  <button
                    className="button-send"
                    onClick={() => setOpen(false)}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </>
          ) : type === "Product" ? (
            <>
              <div className="divInput">
                <input
                  className="input-sign-in"
                  value={val?.produto}
                  onChange={(e) => {
                    setVal((prev: any) => ({
                      ...prev,
                      produto: e.target.value,
                    }));
                    setError((prev: any) => ({
                      ...prev,
                      produto: false,
                    }));
                  }}
                />
                <label style={{ marginBottom: error?.produto && 25 }}>
                  Tipo De Produto
                </label>
                {error?.produto && (
                  <div className="cpf-cnpj-input__error-message">
                    {error.produto}
                  </div>
                )}
              </div>
              <div className="divInput">
                {error.material && (
                  <div className="cpf-cnpj-input__error-message">
                    {error.material}
                  </div>
                )}
                <SelectProduct
                  label="Tipo De Embalagem"
                  options={embalagens}
                  id={val.idProduto}
                  setSelectedOptions={setAllEmbalagens}
                  selectedOptions={allEmbalagens}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  justifyContent: "space-around",
                }}
              >
                <div className="content-button-send">
                  <button
                    className="button-send"
                    onClick={() =>
                      val.produto !== null && val.produto !== "" && Send(val)
                    }
                  >
                    ENVIAR
                  </button>
                </div>
                <div className="content-button-send">
                  <button
                    className="button-send"
                    onClick={() => setOpen(false)}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </>
          ) : type === "Fornecedores" ? (
            <>
              {/* Nome Fornecedor */}
              <div className="divInput">
                <input
                  className="input-sign-in"
                  value={val?.fornecedorDescricao}
                  onChange={(e) => {
                    setVal((prev: any) => ({
                      ...prev,
                      fornecedorDescricao: e.target.value,
                      nomeFantasia: e.target.value,
                    }));
                  }}
                />
                <label>Fornecedor</label>
              </div>

              {/* CPF/CNPJ */}
              <div className="divInput">
                <input
                  className="input-sign-in"
                  maxLength={17}
                  value={val?.cpfCnpj}
                  onChange={handleChange}
                />

                <label style={{ marginBottom: error && 25 }}>CPF/CNPJ</label>
                {error && (
                  <div className="cpf-cnpj-input__error-message">{error}</div>
                )}
              </div>

              {/* Unidades    */}
              <div
                style={{
                  position: "relative",
                  paddingTop: "13px",
                  margin: "12px 0",
                  width: "65%",
                }}
              >
                {method === "putch" && (
                  <div className="divInput" style={{ width: "100%" }}>
                    <button
                      onClick={() => {
                        AddUnit(val.newUnity);
                      }}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 1,
                        fontSize: 16,
                        background: "#83620e",
                        outline: "none",
                        color: "white",
                        border: "none",
                        padding: "8px 8px",
                        borderRadius: 8,
                        zIndex: 9999,
                      }}
                    >
                      Adicionar unidade
                    </button>
                    <input
                      className="input-sign-in"
                      maxLength={50}
                      onChange={(e) => {
                        setVal((prev: any) => ({
                          ...prev,
                          newUnity: [
                            {
                              fornecedorUnidadeDescricao: e.target.value,
                              idFornecedor: val.idFornecedor,
                              deleted: 0,
                            },
                          ],
                        }));
                      }}
                    />
                    <label>Unidades</label>
                  </div>
                )}
                {method === "putch" && (
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}
                  >
                    {val?.unidades?.map((item: any) => {
                      return (
                        item.deleted !== 1 && (
                          <div className="unity-style">
                            <p
                              style={{ cursor: "pointer" }}
                              onClick={() => setEditUnity(item)}
                            >
                              {item.fornecedorUnidadeDescricao}
                            </p>
                          </div>
                        )
                      );
                    })}
                  </div>
                )}
              </div>

              {/* Tipo de Embalagem */}
              {method === "putch" && (
                <div
                  style={{
                    position: "relative",
                    paddingTop: "13px",
                    margin: "12px 0",
                    width: "65%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label style={{ marginRight: 15, color: "#66afe9" }}>
                      Tipos de Embalagem
                    </label>
                    <div
                      ref={selectRef}
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <div
                        id="statusSelect"
                        className="select-forn"
                        style={{
                          outline: "none",
                          width: "100%",
                          height: "auto",
                          minHeight: 40,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            height: "auto",
                          }}
                        >
                          {val?.embalagens?.map((em: any) => {
                              return (
                                em.deleted !== 1 && (
                                  <p
                                    style={{
                                      backgroundColor: "#e8e8e8",
                                      margin: "5px 5px",
                                      padding: "4px 8px",
                                      color: "rgba(0,0,0,.6)",
                                      borderRadius: 4,
                                      fontWeight: 500,
                                      fontSize: 14,
                                      display: "flex",
                                      alignSelf: "center",
                                    }}
                                  >
                                    {em.categoria}
                                    <button
                                      onClick={() => {
                                        setOpenConfirmModal(true);
                                        setOpenSelect(false);
                                        setExclusionData(em);
                                      }}
                                      style={{
                                        outline: "none",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <AiFillDelete
                                        size={20}
                                        style={{ marginLeft: 20 }}
                                      />
                                    </button>
                                  </p>
                                )
                              );
                            })
                          }
                        </div>

                        {OpenSelect && (
                          <div
                            className="content-options-select"
                            style={{ maxHeight: 300, overflowY: "scroll" }}
                          >
                            {embalagens?.map((item: any) => {
                              return (
                                <button
                                  onClick={() => {
                                    handleChangeEmbalagem(
                                      item,
                                      val.idFornecedor
                                    );
                                  }}
                                >
                                  {item.tipoEmbalagem}
                                </button>
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          position: "relative",
                          right: 40,
                          top: 10,
                          width: 0,
                        }}
                      >
                        <button
                          onClick={() => setOpenSelect(!OpenSelect)}
                          style={{
                            marginLeft: "auto",
                            background: "none",
                            outline: "none",
                            border: "none",
                          }}
                        >
                          <MdArrowDropDown size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Botoes     */}
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  position: "relative",
                }}
              >
                <div className="content-button-send">
                  <button
                    className="button-send"
                    onClick={() =>
                      val.fornecedorDescricao.trim() !== null &&
                      val.fornecedorDescricao.trim() !== "" &&
                      val.nomeFantasia.trim() !== "" &&
                      val.nomeFantasia.trim() !== null &&
                      Send(val)
                    }
                  >
                    ENVIAR
                  </button>
                </div>
                <div className="content-button-send">
                  <button
                    className="button-send"
                    onClick={() => setOpen(false)}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </>
          ) : type === "Defeitos" ? (
            <>
              <div className="divInput">
                <input
                  className="input-sign-in"
                  value={val?.tipoDefeito}
                  onChange={(e) => {
                    setVal((prev: any) => ({
                      ...prev,
                      tipoDefeito: e.target.value,
                    }));
                  }}
                />
                <label>Tipo De Defeito</label>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  justifyContent: "space-around",
                }}
              >
                <div className="content-button-send">
                  <button className="button-send" onClick={() => Send(val)}>
                    ENVIAR
                  </button>
                </div>
                <div className="content-button-send">
                  <button
                    className="button-send"
                    onClick={() => setOpen(false)}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </>
          ) : type === "Embalagens" ? (
            <>

              <div className="divInput">
                <input
                  className="input-sign-in"
                  value={val?.idMaterialSap}
                  onChange={(e) => {
                    setVal((prev: any) => ({
                      ...prev,
                      idMaterialSap: e.target.value,
                    }));
                  }}
                />
                <label>ID Material SAP</label>
                {error.idMaterialSap && (
                  <div className="cpf-cnpj-input__error-message">
                    {error.idMaterialSap}
                  </div>
                )}
              </div>

              <div className="divInput">
                <input
                  className="input-sign-in"
                  value={val?.embalagem}
                  onChange={(e) => {
                    setVal((prev: any) => ({
                      ...prev,
                      embalagem: e.target.value,
                    }));
                  }}
                />
                <label>Nome da Embalagem</label>
                {error.embalagem && (
                  <div className="cpf-cnpj-input__error-message">
                    {error.embalagem}
                  </div>
                )}
              </div>

              <div className="divInput">
                <SelectProduct
                  label="Tipo De Embalagem"
                  options={embalagens}
                  id={val.embalagem}
                  setSelectedOptions={setAllEmbalagens}
                  selectedOptions={allEmbalagens}
                />
              </div>

              {method === "putch" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "65%",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <label style={{ marginRight: 15, color: "#66afe9" }}>
                      Tipos de Defeito
                    </label>
                    <button
                      onClick={() => setOpenSelect(!OpenSelect)}
                      className="btn btn-info"
                      style={{ outline: "none", border: "none" }}
                    >
                      Adicionar Defeito
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      background: "grey",
                      margin: "10px 0",
                      padding: "15px 0",
                      width: "100%",
                    }}
                  >
                    {defectByEmbalagem?.map((item: any) => {
                      return (
                        <p
                          style={{
                            backgroundColor: "#e8e8e8",
                            margin: "5px 5px",
                            padding: "4px 8px",
                            color: "rgba(0,0,0,.6)",
                            borderRadius: 4,
                            fontWeight: 500,
                            fontSize: 14,
                            display: "flex",
                            alignSelf: "center",
                          }}
                        >
                          {item.tipoDefeito}
                          <button
                            onClick={() => {
                              setOpenConfirmModal(true);
                              setExclusionPackaging(item);

                              //setExclusionData(item);
                            }}
                            style={{
                              outline: "none",
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            <AiFillDelete
                              size={20}
                              style={{ marginLeft: 20 }}
                            />
                          </button>
                        </p>
                      );
                    })}
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  justifyContent: "space-around",
                }}
              >
                {/* {defectByEmbalagem?.length > 0 && ( */}
                <>
                  <div className="content-button-send">
                    <button
                      className="button-send"
                      onClick={() => patchNewPackage(val, allEmbalagens)}
                    >
                      ENVIAR
                    </button>
                  </div>
                  <div className="content-button-send">
                    <button
                      className="button-send"
                      onClick={() => setOpen(false)}
                    >
                      Voltar
                    </button>
                  </div>
                </>
                {/* )} */}
              </div>
              {OpenSelect && (
                <div className="content-edit">
                  <div
                    className="info-content-edit"
                    ref={selectRef}
                    style={{ height: "65%", overflowY: "auto" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <h1>Defeitos</h1>

                      <div
                        className="divInput"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          position: "relative",
                          width: "100%",
                          margin: "25px auto",
                        }}
                      >
                        <input
                          className="input-sign-in"
                          onChange={(e) => {
                            filtrarDefeito(e.target.value);
                          }}
                        />
                        <label>Filtrar</label>
                      </div>
                    </div>

                    {newDefect.map((item: any) => {
                      return item.idTipoEmbalagem == val.idTipoEmbalagem ? (
                        ""
                      ) : (
                        <button
                          className="button-select-defeito"
                          onClick={() => handleAddDefect(item)}
                        >
                          {item.tipoDefeito}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
          {editUnity && (
            <div className="content-edit">
              <div className="info-content-edit">
                <div style={{ display: "flex" }}>
                  <h1 style={{ fontSize: 20 }}>Editar Unidade</h1>
                  <button
                    onClick={() => setEditUnity(false)}
                    style={{
                      border: "none",
                      outline: "none",
                      background: "none",
                      position: "absolute",
                      right: 10,
                      top: 20,
                    }}
                  >
                    <AiOutlineClose size={20} />
                  </button>
                </div>
                <div className="edit-input">
                  <label>Descrição do fornecedor</label>
                  <input
                    value={editUnity.fornecedorUnidadeDescricao}
                    onChange={(e: any) => {
                      setEditUnity((prev: any) => ({
                        ...prev,
                        fornecedorUnidadeDescricao: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="content-button">
                  <button
                    className="btn btn-primary"
                    style={{ outline: "none" }}
                    onClick={() => patchNewUnity(editUnity)}
                  >
                    Salvar Alterações
                  </button>
                  <button
                    className="btn btn-danger"
                    style={{ outline: "none" }}
                    onClick={() => {
                      setEditUnity((prev: any) => ({
                        ...prev,
                        deleted: 1,
                      }));
                      method === "putch" &&
                        DeleteUnit(editUnity, dataGetUnidadeForn);
                    }}
                  >
                    Deletar Unidade
                  </button>
                </div>
              </div>
            </div>
          )}
          {openConfirmModal && (
            <div className="content-edit">
              <div className="info-content-edit">
                <h1>
                  Deletar {type === "Fornecedores" ? "Embalagem" : "Defeito"}?
                </h1>
                <span style={{ margin: "10px 0" }}>
                  {type === "Fornecedores" ? "Embalagem" : "Defeito"} será
                  permanentemente excluído!
                </span>
                <div className="div-button-confirm">
                  <button
                    style={{
                      background: "#d83123",
                      borderRadius: 4,
                      color: "white",
                      marginBottom: "5px",
                    }}
                    className="button-confirm"
                    onClick={() => {
                      setOpenSelect(false);
                      setOpenConfirmModal(false);
                      type === "Defeitos" ||
                      (type === "Embalagens" && exclusionPackaging)
                        ? HandleDeleteDefect(exclusionPackaging)
                        : type === "Fornecedores" &&
                          exclusionData &&
                          handleDeleteEmbalagemForn(exclusionData);
                    }}
                  >
                    Deletar
                  </button>
                  <button
                    className="button-confirm"
                    onClick={() => {
                      setOpenConfirmModal(false);
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          )}
          {modalText && (
            <div className="content-edit">
              <div
                className="info-content-edit"
                style={{ height: "20%", justifyContent: "center" }}
              >
                <div style={{ display: "flex", alignContent: "center" }}>
                  <h1 style={{ fontSize: 20, color: "green" }}>{modalText}</h1>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="content-edit" style={{ background: "none" }}>
          <div className="content-loading">
            <img src="/assets/img/loading.gif" alt="loading" />
          </div>
        </div>
      )}
    </ErrorBoundary>
  );
};

export default SignIn;
