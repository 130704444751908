import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js/auto";

ChartJs.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
)

interface Props {
  data: any;
  options?: any;
  onClick?: any;
  ref?: any;
}

const BarChat = ({ data, options, onClick, ref }: Props) => {
  return (
    <div>
      <Bar               
          style={{
                display: "inline",
                height:"max",
                width: "max"
          }} data={data} onClick={onClick} options={options ? options : []} />
    </div>
  );
};

export default BarChat;
