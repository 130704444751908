import React, { useCallback, useEffect, useRef } from "react";
import "../../ui/components/Nce/nce.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { ImFileText2 } from "react-icons/im";
import { AiFillInfoCircle } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { BsCircle } from "react-icons/bs";
import {
  GetListaNceRequest,
  EditarNaoConformidade,
  GetListNce,
  GetNceRequest,
  PostPublishedNce,
  VoltarEtapaWorkflow,
  DeleteNce
} from "../../data/services";
import Multisteps from "../../ui/components/itensdoformulario/Multisteps";
import Modal from "../../ui/components/modal/modal";
import useModal from "../../hooks/useModal";
import { useAuth } from "../../context/auth";
import { StringisNullOrEmpity } from "../../helpers/helpers";
import ErrorBoundary from "../../ui/components/Error";
import { ErrorContext } from "../../context/ErrorContext";

import secureLocalStorage from "react-secure-storage";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Dados = {
  tipoEmbalagem: any;
  fornecedorDescricao: string;
  gpUnidadeDescricao: string;
  dataPublicacao: any;
  status: any;
  setData: (value: any) => void;
};

const Nce = () => {
  const [opend, setOpend] = React.useState<boolean>(true);
  const [openModalSend, setOpenModalSend] = React.useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = React.useState<boolean>(false);
  const [palavraChave, setPalavraChave] = React.useState<string>("");
  const [openNce, setOpenNce] = React.useState<any>(false);
  const [openModal, setOpenModal] = React.useState<any>({
    open: false,
  });
  const [comentarioNCE, setComentarioNCE] = React.useState<string>("");
  const [resetFiltro, setResetFiltro] = React.useState<string>("");
  const [status, setStatus] = React.useState<string>("");
  const [option, setOption] = React.useState<any>([]);
  const [optionFiltro, setOptionFiltro] = React.useState<any>([]);
  const [statusList, setStatusList] = React.useState<string[]>([]);
  const [itemSendNce, setItemSendNce] = React.useState<any>({});
  const { isOpen, toggle } = useModal();
  const [loading, setLoading] = React.useState<boolean>(false);

  const type = "nces" ? "Nce" : "vazio";
  const { user } = useAuth();
  const { showError } = React.useContext(ErrorContext);
  const login = secureLocalStorage.getItem("login");

  const [deleteData, setDeleteData] = React.useState<any>(false);

  const OpenModalOption = useCallback((obj: any, open: boolean) => {
    obj.open = open;
  }, []);

  useEffect(() => {
    setOption((prev: any) => [...prev]);
  }, [opend]);

  useEffect(() => {

    if (statusList.length == 0 && option.length > 0) {
      var valores = ["Todos"];
      option.map((a: any) => {
        let status: string = a.ETAPA_DESCRICAO;
        if (!valores.includes(status)) {
          valores.push(a.ETAPA_DESCRICAO);
        }
      });
      setStatusList(valores);
      setOptionFiltro(option);
    }

  }, [option]);

  const tagRef: any = useRef(null);

  function buscarPorTexto(event: any) {
    const txtBusca = event.target.value.toUpperCase()

    setResetFiltro(txtBusca);
    let filter: any

    if (!StringisNullOrEmpity(status) && status !== "Todos") {
      filter = option.filter((a: any) =>
        a?.ETAPA_DESCRICAO === status &&
        (a.ID_NCE.toUpperCase().includes(txtBusca) ||
          a?.ETAPA_DESCRICAO?.toUpperCase().includes(txtBusca) ||
          a?.DATA_ABERTURA?.toUpperCase().includes(txtBusca) ||
          a?.GP_UNIDADE?.toUpperCase().includes(txtBusca) ||
          a?.FORNECEDOR?.toUpperCase().includes(txtBusca) ||
          a?.EMBALAGEM?.toUpperCase().includes(txtBusca)
        ));

      setOptionFiltro(filter);
    }
    else {
      filter = option.filter((a: any) =>
        a.ID_NCE.toUpperCase().includes(txtBusca) ||
        a?.ETAPA_DESCRICAO?.toUpperCase().includes(txtBusca) ||
        a?.DATA_ABERTURA?.toUpperCase().includes(txtBusca) ||
        a?.GP_UNIDADE?.toUpperCase().includes(txtBusca) ||
        a?.FORNECEDOR?.toUpperCase().includes(txtBusca) ||
        a?.EMBALAGEM?.toUpperCase().includes(txtBusca)
      );
      setOptionFiltro(filter);
    }
  }

  const handleOpenModalSend = async (item: any) => {
    setOpenModalSend(true);
    setItemSendNce(item);
  };

  const handleOpenModalDelete = async (item: any) => {
    setOpenModalDelete(true);
    setItemSendNce(item);
  };

  const ListColumns = React.useMemo(() => {
    return (
      <>
        <td className="listProperty">Número NCE</td>
        <td className="listProperty">Status</td>
        <td className="listProperty">Data</td>
        <td className="listProperty">Unidade do Solicitante</td>
        <td className="listProperty">Fornecedor</td>
        <td className="listProperty">Material</td>
        <td className="listProperty" />
      </>
    );
  }, []);

  //INICIAR TELA
  React.useEffect(() => {
    CarregarLista();
  }, []);

  const handleEmail = async () => {
    setLoading(true)
    setOpenModalSend(false);
    const login = secureLocalStorage.getItem('login')
    await PostPublishedNce(login, itemSendNce.ID_NCE, comentarioNCE, showError).then(() => {
      setLoading(false)
      ResetarLista()
      toast.success("Processo executado com sucesso!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }).catch((error: any) => {
      let msgError = `${error?.response?.data?.mensagem}. Usuário: ${login} IDNCE: ${itemSendNce.ID_NCE}`;
      setLoading(false)
      toast.error(StringisNullOrEmpity(msgError) ? `Ocorreu um erro ao enviar os e-mails! Usuário: ${login} IDNCE: ${itemSendNce.ID_NCE}` : msgError, {
        position: toast.POSITION.TOP_CENTER,
      });
    })
  };

  async function Delete (idNce: any) {
    await DeleteNce(itemSendNce.ID_NCE, showError) 

    const filter = option.filter((a: any) => a.ID_NCE != itemSendNce.ID_NCE)
    setOption(filter)
    setOptionFiltro(filter)
    setOpenModalDelete(false);
  }

  //FILTRO STATUS
  useEffect(() => {
    if (status !== "Todos") {
      const filter = option.filter((item: any) => {
        return item.ETAPA_DESCRICAO === status;
      });

      setOptionFiltro(filter);
      setResetFiltro("");
    }
    else {
      setOptionFiltro(option);
      setResetFiltro("");
    }
  }, [status]);

  async function voltarEtapaWorkflow(item: any) {
    setLoading(true)
    await VoltarEtapaWorkflow(user.username, item.ID_NCE, item.ID_EVENTO, showError).then((res: any) => {
      setLoading(false)
      ResetarLista()
      toast.success("Volta de Etapa feita com sucesso", {
        position: toast.POSITION.TOP_CENTER,
      });
    }).catch((error: any) => {
      let msgError = error?.response?.data?.mensagem;
      secureLocalStorage.clear()
      setLoading(false)
      toast.error(StringisNullOrEmpity(msgError) ? "Ocorreu um erro ao voltar a etapa de workflow.Atualize a página e tente novamente!" : msgError, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
  }

  async function CarregarLista() {
    setLoading(true)
    await GetListaNceRequest(user.username).then((res: any) => {
      setOption(res.data)
      setOptionFiltro(res.data)
    }).catch(() => {
      secureLocalStorage.clear()
      toast.error("Ocorreu um erro ao carregar a lista de NCE's. Atualize a página e tente novamente!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false)
    })
    setLoading(false)
  }

  async function ResetarLista() {
    setLoading(true)
    await CarregarLista();
    setResetFiltro("");
    setStatus("Todos");
    setOptionFiltro(option);
    setLoading(false)
  }

  async function editarNCE(item: any) {
    setLoading(true)
    await EditarNaoConformidade(item.ID_NCE).then((res: any) => {
      setOpenNce({
        open: true,
        object: res.data[0],
      })
      setLoading(false)
    }).catch(() => {
      secureLocalStorage.clear()
      toast.error('Ocorreu um erro ao editar a NCE. Atualize a página e tente novamente!', {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false)
    })
    setLoading(false)
  }

  return (
    <ErrorBoundary>
      {!loading && (
        <>
          <div>
            <ToastContainer />
            <div className="header">
              <h1>Não Conformidades</h1>
            </div>
            <div className="info-nce">
              <div className="content-select">
                <label style={{ marginRight: 15, color: "#555555" }}>Status</label>
                <select
                  id="statusSelect"
                  name=""
                  className="ls-select"
                  value={(status)}
                  style={{ outline: "none", width: "85%", textAlign: "left" }}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {statusList.map((item: any) => {
                    return <option value={item}>{item}</option>;
                  })}
                </select>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ marginRight: 15, color: "#555555" }}>Filtro</label>
                <input
                  placeholder="texto para busca"
                  type="text"
                  onChange={buscarPorTexto}
                  value={resetFiltro}
                  style={{
                    marginRight: 10,
                    border: "1px solid #dddddd",
                    textAlignLast: "left",
                    borderRadius: 4,
                    height: 30,
                    fontSize: 12,
                    paddingLeft: 5,
                  }}
                />
              </div>
            </div>
            <div ref={tagRef} className="content">
              <div className="contentListNce">{ListColumns}</div>
              <div>
                {optionFiltro.length > 0 ? (
                  // <>{List}</>
                  <>
                    {optionFiltro?.map((item: any) => {
                      return (
                        <>
                          <tr
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "20px 0",
                              borderBottom: "1px solid #dddddd",
                            }}
                          >
                            <td className="listTd" style={{ color: "#a38e17" }}>
                              <AiFillInfoCircle
                                color="grey"
                                size={15}
                                style={{ marginRight: 8, cursor: "pointer" }}
                                onClick={() => {
                                  setOpenModal((prev: any) => ({
                                    ...prev,
                                    open: true,
                                    obj: item,
                                  }));
                                }}
                              />
                              <ImFileText2 style={{ marginRight: 8 }} />
                              <p
                                style={{ cursor: "pointer", margin: 0 }}
                                onClick={() =>
                                  editarNCE(item)
                                }
                              >
                                {item.ID_NCE}
                              </p>
                            </td>
                            <td className="listTd">
                              {item.ETAPA_DESCRICAO}
                            </td>
                            <td className="listTd">{item.DATA_ABERTURA}</td>
                            <td className="listTd">
                              {item.GP_UNIDADE}
                            </td>
                            <td className="listTd">
                              {item.FORNECEDOR}
                            </td>
                            <td className="listTd">
                              {item.EMBALAGEM}
                            </td>
                            <td className="listTd">
                              <button
                                className="button-details"
                                onBlur={() => {
                                  OpenModalOption(item, item.open && false);
                                }}
                                onClick={() => {
                                  setOpend(!opend);
                                  OpenModalOption(
                                    item,
                                    item.open ? !item.open : true
                                  );
                                }}
                              >
                                <IoMdArrowDropdown size={20} />
                              </button>
                            </td>
                            {item.open && (
                              <div className="Dropdown">
                                <div className="modal-options">
                                  {item.open && 
                                    (
                                      (user.tipo === "GP" && (
                                      item.ETAPA_DESCRICAO === "ABERTURA" || 
                                      item.ETAPA_DESCRICAO === "ACEITE" || 
                                      item.ETAPA_DESCRICAO === "VERIFICACAO DE EFICACIA"
                                      )) ||
                                      (user.tipo === "FORN" && (
                                        item.ETAPA_DESCRICAO === "CONTENCAO" || 
                                        item.ETAPA_DESCRICAO === "PLANO DE ACAO"
                                      ))
                                    ) ? (
                                    <button className="public-button" onClick={() => handleOpenModalSend(item)}>
                                      Publicar
                                    </button>
                                  ) : 
                                  <button className="light-button" disabled>
                                    STATUS: {item.ETAPA_DESCRICAO}
                                  </button>
                                  }

                                  {item.open && 
                                    (
                                      (user.tipo === "GP" && (
                                      item.ETAPA_DESCRICAO === "ACEITE" || 
                                      item.ETAPA_DESCRICAO === "VERIFICACAO DE EFICACIA"
                                      )) ||
                                      (user.tipo === "FORN" && (
                                        item.ETAPA_DESCRICAO === "CONTENCAO" || 
                                        item.ETAPA_DESCRICAO === "PLANO DE ACAO"
                                      ))
                                    ) ? (
                                      <button onClick={() => voltarEtapaWorkflow(item)} className="delete-button">
                                        Voltar Etapa Workflow
                                      </button>
                                    ) : null}

                                  {item.open && 
                                    user.tipo === "GP" && (
                                      item.ETAPA_DESCRICAO === "ABERTURA"
                                    ) ? (
                                    <button className="delete-button" onClick={() => handleOpenModalDelete(item)}>
                                      Deletar
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                              )}
                          </tr>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <h1 style={{ margin: "35px 0", fontSize: 28 }}>
                      Não há registros aqui
                    </h1>
                  </div>
                )}
              </div>
            </div>
            {openModal.open && (
              <div className="flex-box">
                <div className="content-box">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h1 className="title-id-nce">
                      WorkFlow | {openModal.obj.nceId}
                    </h1>
                    <button
                      className="close-modal-status"
                      onClick={() => setOpenModal(false)}
                    >
                      <AiOutlineClose color="grey" size={20} />
                    </button>
                  </div>
                  <ul className="content-list-status" style={{ paddingLeft: 20 }}>
                    <li style={{ color: "red" }}>
                      <BsCircle style={{ marginRight: 10 }} />
                      ABERTURA
                    </li>
                    <li style={{ color: "red" }}>
                      <BsCircle style={{ marginRight: 10 }} />
                      CONTENÇÃO
                    </li>
                    <li style={{ color: "red" }}>
                      <BsCircle style={{ marginRight: 10 }} />
                      PLANO DE AÇÃO
                    </li>
                    <li style={{ color: "red" }}>
                      <BsCircle style={{ marginRight: 10 }} />
                      ACEITE
                    </li>
                    <li style={{ color: "red" }}>
                      <BsCircle style={{ marginRight: 10 }} />
                      VERIFICAÇÃO DE EFICÁCIA
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {openNce.open && (
              <Modal isOpen={openNce.open} toggle={toggle}>
                <div className="row">
                  <p className="col-md-2 text-center"></p>
                  <p className="col-md-8 text-center">
                    <strong className="col-md-12 text-center">
                      NCE - NÃO CONFORMIDADE DE EMBALAGENS
                    </strong>
                  </p>
                  <p className="col-md-2 text-center">
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      data-dismiss="close"
                      onClick={() => setOpenNce(false)}
                    >
                      <AiOutlineClose />
                    </button>
                  </p>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <Multisteps item={openNce.object} />
                  </div>
                </div>
              </Modal>
            )}
          </div>
          {openModalSend && (
            <div className="overlay">
              <div className="modal-nce">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 0",
                    borderBottom: "1px solid grey",
                    padding: 10,
                  }}
                >
                  <h1 style={{ fontSize: 18, margin: 0 }}>
                    Deseja publicar as alterações da NCE?
                  </h1>
                  <button
                    style={{
                      background: "none",
                      outline: "none",
                      border: "none",
                      color: "grey",
                      fontWeight: "bold",
                    }}
                    onClick={() => setOpenModalSend(false)}
                  >
                    x
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    margin: "15px 0",
                    borderBottom: "1px solid grey",
                    padding: 10,
                  }}
                >
                  <span style={{ margin: "8px 0" }}>
                    Todos usuários do sistema e o fornecedor receberá um email informando a publicação desta nce.
                  </span>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      margin: 15,
                    }}
                  >
                    <textarea
                      onChange={(e) => setComentarioNCE(e.target.value)}
                      style={{
                        margin: 0,
                        outline: "none",
                        border: "1px solid grey",
                        borderRadius: 4,
                      }}
                      placeholder="Comentário..."
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "rgb(163, 142, 23)",
                      color: "white",
                      outline: "none",
                      border: "none",
                      borderRadius: 6,
                      padding: "10px 15px",
                    }}
                    onClick={() => handleEmail()}
                  >
                    Salvar comentário
                  </button>
                </div>
              </div>
            </div>
          )}

          {openModalDelete && (
            <div className="overlay">
              <div className="modal-nce-delete">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 0",
                    borderBottom: "1px solid grey",
                    padding: 10,
                  }}
                >
                  <h1 style={{ fontSize: 18, margin: 0 }}>
                    {`Deseja Excluir a NCE ${itemSendNce.ID_NCE}`}?
                  </h1>
                  <button
                    style={{
                      background: "none",
                      outline: "none",
                      border: "none",
                      color: "grey",
                      fontWeight: "bold",
                    }}
                    onClick={() => setOpenModalDelete(false)}
                  >
                    x
                  </button>
                </div>
                <div style={{
                  margin: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                  columnGap: "10px"
                }}
                >
                  <button
                    style={{
                      backgroundColor: "rgb(163, 142, 23)",
                      color: "white",
                      outline: "none",
                      border: "none",
                      borderRadius: 6,
                      padding: "10px 15px",
                    }}
                    onClick={() => setOpenModalDelete(false)}
                  >
                    Não
                  </button>
                   <button
                      style={{
                        background: "#d83123",
                        borderRadius: 4,
                        color: "white",
                      }}
                      className="button-confirm"
                      onClick={() => {
                        Delete(deleteData.idNce);
                      }}
                    >
                      Sim
                    </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {loading && (
        <div className="content-edit" style={{ background: "none" }}>
          <div className="content-loading">
            <img src="/assets/img/loading.gif" alt="loading" />
          </div>
        </div>
      )}

    </ErrorBoundary>
  );
};

export default Nce;
