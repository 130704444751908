import React, { useCallback } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import Axios from "./api";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import { error } from "console";
import { format, parseISO } from "date-fns";
import BR from 'date-fns/locale/pt-BR'
interface PropsLogin {
  username: string;
  password: string;
  profile: string;
  setVal?: any;
}

interface PropsPatchUser {
  id?: number;
  value?: any;
}

interface Error {
  name?: string;
  message?: string;
  data?: string;
  code?: string;
}

interface CustomError extends AxiosError {
  response?: AxiosResponse<any, any> | undefined;
}

export const GetNce = (setVal: any) => {
  Axios.get("/nce").then((res: any) => {
    setVal(res.data);
  });
};

export const GetLastNce = (setVal: any) => {
  Axios.get("/nce/last").then((res: any) => {
    setVal(res.data);
  });
};

export const GetNceDiaUtils = (setVal: any, data: string, nDays: number) => {

  Axios.get(`/nce/contagemDiaUtil/${data}/${nDays}`).then((res: any) => {
    setVal(format(parseISO(res.data[0].proxDiaUtil), 'dd/MM/yyyy', { locale: BR}))    
  })
}

export const CreateNceForm = async (body: any) => {
  Axios.post('/nce/createNce',{
    ...body
  })
}
export const UpdateCreateNceForm = async (body: any) => {
  Axios.post('/nce/updateNce',{
    ...body
  })
}

export const PostImgAws = async (fileName: any, file: any) => {
  try {
    const {data} = await axios.post(
      "https://1dd30god0l.execute-api.sa-east-1.amazonaws.com/dev/bucketnotifiergp",
      {
        fileName: fileName,
        file: file
      }
    );

    return data.url;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetAcessos = (setVal: any) => {
  Axios.get("/acessos/last").then((res: any) => {
    setVal(res.data);
  });
};

export const GetEventos = (setVal: any) => {
  Axios.get("/workflow-eventos/last").then((res: any) => {
    setVal(res.data);
  });
};


export const GetGpUnidade = (setVal: any) => {
  Axios.get("/gp-unidade").then((res: any) => {
    setVal(res.data);
  });
};

export const GetProduto = (setVal: any) => {
  Axios.get("/produto").then((res: any) => {
    setVal(res.data);
  });
};

export const GetProdutoFornNCE = (setVal: any, idEmbalagem: any) => {

    Axios.get(`/produto/${idEmbalagem}`).then((res: any) => {
      const produto = res.data.map((item: any) => item.PRODUTO);
      setVal(produto);
    }).catch((error: any) => {
      setVal([]);
  });
};


export const PostProduct = (val: any) => {
  Axios.post(`/produto`, {
    ...val,
  });
};

export const PostBatchProducts = (product: any, embalagens: any) => {
  Axios.post(`/produto/batch/${product}`,{
    embalagens
  })
}

export const PatchProduct = (id: any, val: any) => {
  Axios.patch(`/produto/${id}`, {
    ...val,
  });
};

export const DeleteProduct = (id: any) => {
  Axios.delete(`/produto/${id}`);
};

export const GetUnidade = (setVal: any) => {
  Axios.get("/fornecedor-unidade").then((res: any) => {
    setVal(res.data);
  });
};

export const GetUnidadeUsers = async () => {
  return Axios.get("/fornecedor-unidade").then((res: any) => {
    return res.data;
  });
};

export const GetUnidadeFornNCE = (setVal: any, idForn: any) => {
  Axios.get("/fornecedor-unidade").then(async (res: any) => {
    let unidades: any[] = [];

    res.data.filter(
      (i: any) => i.idFornecedor == parseInt(idForn) && unidades.push(i)
    );
    setVal(unidades.filter((a: any) => a.deleted == 0));
  });
};

export const AuthLogin = async ({
  username,
  password,
  profile,
  setVal,
}: PropsLogin) => {
  try {
    const response = await axios.post(
      `https://0h4sssk2fb.execute-api.us-east-1.amazonaws.com/prod/auth/login`,
      // `http://localhost:3000/auth/login`,
      // `${https://0h4sssk2fb.execute-api.us-east-1.amazonaws.com/prod}/auth/login`,
      {
        username: username,
        password: password,
        profile: profile,
      }
    );

    setVal(response);
  } catch (error) {
    setVal(error);
    throw error;
  }
};

export const PostNewPassword = async (obj: any) => {
  try {
    const response = await Axios.post(`/usuarios/confirmation`, { ...obj });
    return response;
  } catch (error) {
    console.error("Error in PostNewPassword:", error);
    throw error;
  }
};

export const ResetPassword = async (body: any, setError: any) => {
  try {
    await Axios.post(`/usuarios/resetSenha`, {
      ...body
    })

  } catch (error) {
    const { response: { data: { mensagem } = { mensagem: '' } } = {} } = error as CustomError;

    console.log("mensagem", mensagem);
    const err = mensagem || "Houve um erro inesperado"
    setError({
      error: true,
      message: err,
    });
    throw new Error(err)
  }
};

export const SendCode = async (body: any, setError: any) => {
  try {
    await Axios.post(`/usuarios/newCode`, {
      ...body
    })

  } catch (error) {
    const { response: { data: { mensagem } = { mensagem: '' } } = {} } = error as CustomError;

    const err = mensagem || "Houve um erro inesperado"
    setError({
      error: true,
      message: err,
    });
    throw new Error(err)
  }
};

export const GetUsers = (setVal: any) => {
  Axios.get(`/usuarios`)
    .then(async (res: any) => {
      setVal(res.data);
    })
    .catch((e) => {
      console.log("e", e);
      const error: Error = {
        name: e.name,
        code: e.code,
        data: e.config.data,
        message: e.message,
      };
      console.log("error message", error);
    });
};

export const GetUsersResume = (setVal: any) => {
  Axios.get("/usuarios/resume").then((res: any) => {
    setVal(res.data);
  });
};

export const GetUsersInfo = (setUser: any, login: any) => {
  Axios.get(`/usuarios/resumeUser/${login}`).then((res: any) => {
    setUser(res.data);
  });
};

export const PatchUser = ({ id, value }: PropsPatchUser) => {
  Axios.patch(`/usuarios/${id}`, {
    ...value,
  });
};

export const PostUser = async (val: any, showError: any) => {
  try {
        const response = await Axios.post(`/usuarios`, {
      ...val,
    });
    return response;
  } catch (err: any) {
    console.log('Error:', err)
    if (err.response && err.response.status) {
      showError(`Erro: ${err.response.data.mensagem}`);
    } else if (err.message) {
      showError(`Http Error: ${err.message}`);
    } else {
      showError(`Erro Inesperado ao criar usuário: ${err.status}`);
    }
  }
};

export const DeleteUser = (id: any) => {
  Axios.delete(`/usuarios/${id}`);
};

export const GetSuppliers = (setVal: any) => {
  Axios.get("/fornecedor").then((res: any) => {
    setVal(res.data);
  });
};

export const GetSuppliersById = (setVal: any, idFabrica: any) => {
  Axios.get(`/fornecedor/${idFabrica}`).then((res: any) => {
    setVal([res.data]);
  });
};

export const GetSuppliersByUnity = (setVal: any) => {
  Axios.get("/fornecedor-unidade").then((res: any) => {
    setVal(res.data);
  });
};

export const GetByUnityBySuppliers = (setVal: any, idFabrica: any) => {
  Axios.get(`/fornecedor-unidade/unidades/${idFabrica}`).then((res: any) => {
    setVal(res.data);
  });
};


export const GetSuppliersByEmbalagens = (setVal: any) => {
  Axios.get("/tipo-embalagem-fornecedor").then((res: any) => {
    setVal(res.data);
  });
};

export const PatchSuppliers = ({ id, value }: PropsPatchUser) => {
  const object = {
    cpfCnpj: value.cpfCnpj,
    deleted: value.deleted,
    fornecedorDescricao: value.fornecedorDescricao,
    idFornecedor: value.idFornecedor,
    idUsuario: value.idUsuario,
    nomeFantasia: value.nomeFantasia,
  };

  Axios.patch(`/fornecedor/${id}`, {
    ...object,
  });
};

export const PostSuppliers = (value: any) => {
  Axios.post(`/fornecedor`, {
    ...value,
  });
};

export const DeleteSupplier = (id: any) => {
  Axios.delete(`/fornecedor/${id}`);
};

export const GetMaterials = (setVal: any) => {
  Axios.get("/material").then((res: any) => {
    setVal(res.data);
  });
};

export const PatchMaterial = ({ id, value }: PropsPatchUser) => {
  Axios.patch(`/material/${id}`, {
    ...value,
  });
};

export const PostMaterial = (value: any, id: any) => {
  Axios.post(`/material`, {
    ...value,
    idMaterial: id,
  });
};

export const DeleteMaterial = (id: any) => {
  Axios.delete(`/material/${id}`);
};

export const GetDataFeriado = (setVal: any) => {
  Axios.get("/feriados").then((res: any) => {
    setVal(res.data);
  });
};

export const PostFeriado = (value: any) => {
  Axios.post(`/feriados`, {
    ...value,
  });
};

export const DeleteFeriado = (id: any) => {
  Axios.delete(`/feriados/${id}`);
};

export const PatchFeriado = ({ id, value }: PropsPatchUser) => {
  Axios.patch(`/feriados/${id}`, {
    ...value,
  });
};

export const GetDefects = (setVal: any) => {
  Axios.get("/tipo-de-defeito").then((res: any) => {
    setVal(res.data);
  });
};

export const GetDefectsFornNCE = (setVal: any, idEmbalagem: any) => {
  Axios.get(`/tipo-de-defeito/emb/${idEmbalagem}`).then(async (res: any) => {
    let defeitos: any[] = [];

    res.data.filter(
      (i: any) => i.idEmbalagem == parseInt(idEmbalagem) && defeitos.push(i)
    );
    setVal(defeitos);
  });
};

export const GetfornecedorUnidades = () => {
  return Axios.get("/fornecedor-unidade/unidades").then((res: any) => {
    return res.data;
  });
};

export const GetGpUnidades = () => {
  return Axios.get("/gp-unidade").then((res: any) => {
    return res.data;
  });
};

export const PatchDefects = (value: any, id: any) => {
  Axios.patch(`/tipo-de-defeito/${id}`, {
    ...value,
  });
};

export const PostDefect = (value: any) => {
  Axios.post(`/tipo-de-defeito`, {
    ...value,
  });
};

export const DeleteDefects = (id: any) => {
  Axios.delete(`/tipo-de-defeito/${id}`);
};

export const GetEmbalagens = (setVal: any) => {
  Axios.get("/tipo-de-embalagem").then((res: any) => {
    setVal(res.data);
  });
};

export const GetEmbalagem = (setVal: any) => {
  Axios.get("/embalagem").then((res: any) => {
    setVal(res.data);
  });
};

export const GetEmbalagemForCategory = async (id: any) => {
  const response = await Axios.get(`/embalagem/categoriaembalagem/${id}`);
  return response.data;
}

export const GetEmbalagemFornNCE = (setVal: any, idForn: any) => {
  Axios.get(`/embalagem/forn/${idForn}`).then(async (res: any) => {
    setVal(res.data);
  });
};

export const PostFornecedorEmbalagem = (value: any) => {
  Axios.post("/tipo-embalagem-fornecedor", {
    ...value,
  });
};

export const PostFornecedorUnidade = (value: any) => {
  console.log(value, "value");
  Axios.post("/fornecedor-unidade", {
    idFornecedor: value[0].idFornecedor,
    fornecedorUnidadeDescricao: value[0].fornecedorUnidadeDescricao,
    deleted: 0,
  });
};

export const PatchFornecedorUnidade = (value: any, id: any) => {
  console.log(value, "value");
  Axios.patch(`/fornecedor-unidade/${id}`, {
    idFornecedor: value.idFornecedor,
    idFornecedorUnidade: value.idFornecedorUnidade,
    fornecedorUnidadeDescricao: value.fornecedorUnidadeDescricao,
    deleted: 0,
  });
};

export const DeleteFornecedorUnidades = (id: any) => {
  Axios.delete(`/fornecedor-unidade/${id}`);
};

export const PatchFornecedorEmbalagem = (id: any) => {
  Axios.delete(`/fornecedor_tipo_embalagem_fabricada/${id}`);
};

export const PatchEmbalagensAdm = (id: any, value: any) => {
  Axios.patch(`/tipo-de-embalagem/${id}`, {
    ...value,
  });
};

export const DeleteEmbalagens = (id: any) => {
  Axios.delete(`/tipo-de-embalagem/${id}`);
};

export const PostCategoryEmbalagens = (value: any) => {
  Axios.post(`/embalagem-por-tipo-de-embalagem`, {
    ...value,
  });
};

export const PostEmbalagens = (value: any) => {
  Axios.post(`/embalagem`, {
    ...value,
  });
};

export const PostNewDefects = (value: any) => {
  Axios.post(`/tipo-de-defeito`, {
    ...value,
  });
};

export const DeleteDefect = (id: any) => {
  Axios.delete(`/tipo-de-defeito/${id}`);
};

export const PatchUserUnidade = (value: any, id: any) => {
  Axios.patch(`/usuarios-unidades/${id}`, {
    ...value,
  });
};

export const DeleteUserUnidade = (id: any) => {
  Axios.patch(`/usuarios-unidades/${id}`);
};

export const GetUsersUnidade = (setVal: any) => {
  Axios.get(`/usuarios-unidades`).then((res: any) => {
    console.log();

    return setVal(res.data);
  });
};

export const PostUsersUnidade = (value: any) => {
  Axios.post(`/usuarios-unidades`, {
    ...value,
  });
};

export const GetListNce = (setVal: any, idFabrica: any, perfil: any) => {
  Axios.get(`/rep-lista-nc/1/1000`).then(async (res: any) => {
    let nce: any[] = [];

    const filterForn = async () => {
      res.data.filter(
        (i: any) => i.idFornecedor == parseInt(idFabrica) && nce.push(i)
      );
    };

    if (perfil === "usuarioFornecedor") {
      const retorno = await filterForn();
      console.log("retorno", retorno);
      setVal(nce);
    } else {
      console.log("else");
      setVal(res.data);
    }
  });
};

export const GetNceRequest = async (setVal: any, login: any) => {
  await Axios.get(
    `/dashboards/NaoConformidades/resumoDeNaoConformidades/${login}`
  ).then((res: any) => {
    let result = res.data
    console.log(result)
    result.map((a: any) => {
      if (a.WORKFLOW?.etapa_descricao == undefined) {
        a.WORKFLOW = { etapa_descricao: "FINALIZADA" }
      }
    })
    console.log(result)
    setVal(res.data);
  });
};

export const GetListaNceRequest = async (login: any) => {
  return await Axios.get(`/dashboards/NaoConformidades/listaNaoConfirmidade/${login}`)
};

export const EditarNaoConformidade = async (idNCE: any) => {
  return await Axios.get(`/dashboards/NaoConformidades/editarNaoConformidade/${idNCE}`)
};


export const GetPQMFAnalatico = (
  setVal: any,
  year: number,
  forn: number,
  unit: number
) => {
  Axios.get(
    `/dashboards/PQMF_Analitico/graficoAnual/${year}/${forn}/${unit}`
  ).then((res: any) => {
    return setVal(res.data);
  });
};

export const GetPQMFAnalaticoMensal = (
  setVal: any,
  year: number,
  forn: number,
  unit: number
) => {
  Axios.get(
    `/dashboards/PQMF_Analitico/graficoMensal/${year}/${forn}/${unit}`
  ).then((res: any) => {
    return setVal(res.data);
  });
};

export const GetPQMFAnaliticoDesdobramento = (
  setVal: any,
  fechamento: number,
  planta: number
) => {
  Axios.get(
    `dashboards/PQMF_Analitico/graficoDesdobramento01/${fechamento}/${planta}`
  ).then((res: any) => {
    return setVal(res.data);
  });
};

export const GetPQMFAnaliticoListaNce = (
  setVal: any,
  fechamento: number,
  planta: number
) => {
  Axios.get(`dashboards/PQMF_Analitico/listaNce/${fechamento}/${planta}`).then(
    (res: any) => {
      return setVal(res.data);
    }
  );
};

export const GetPQMFEstratificacaoSeveridade = (
  setVal: any,
  severidade: any,
  ano: any,
  forn: any,
  unidade: any
) => {
  Axios.get(
    `/dashboards/PQMF_EstratificacoesDeDefeitos/paretoFornecedoresPorSeveridade/${severidade}/${ano}/${forn}/${unidade}`
  ).then((res: any) => {
    return setVal(res.data);
  });
};

export const GetPQMFEstratificacaoPrincipaisDefeitos = (
  setVal: any,
  ano: any,
  forn: any,
  unidade: any
) => {
  Axios.get(
    `/dashboards/PQMF_EstratificacoesDeDefeitos/paretoPrincipaisDefeitos/${ano}/${forn}/${unidade}`
  ).then((res: any) => setVal(res.data));
};

export const GetPQMFEstratificacaoPrincipaisDefeitosOrigens = (
  setVal: any,
  ano: any,
  forn: any,
  unidade: any
) => {
  Axios.get(
    `/dashboards/PQMF_EstratificacoesDeDefeitos/paretoPrincipaisOrigensDeDefeitos/${ano}/${forn}/${unidade}`
  ).then((res: any) => setVal(res.data));
};

export const GetPQMFEstratificacaoOrigem = (
  setVal: any,
  origem: any,
  ano: any,
  forn: any,
  unidade: any
) => {
  Axios.get(
    `/dashboards/PQMF_EstratificacoesDeDefeitos/paretoFornecedoresPorOrigem/${origem}/${ano}/${forn}/${unidade}`
  ).then((res: any) => {
    return setVal(res.data);
  });
};

export const GetMensal = (setVal: any) => {
  Axios.get(`/dashboards/PQMF_EvolucaoMensal/mensal/todos`).then((res: any) => {
    console.log("res.data", res.data);
    return setVal(res.data);
  });
};

export const GetMensalByForn = (setVal: any, idFabrica: any) => {
  Axios.get(`/dashboards/PQMF_EvolucaoMensal/mensal/${idFabrica}`).then(
    (res: any) => {
      return setVal(res.data);
    }
  );
};

export const GetComparativo = (setVal: any, year: any, unidades: any) => {
  Axios.get(
    `/dashboards/PQMF_Comprativo/graficoComparativo/${year}/${unidades}`
  ).then((res: any) => {
    return setVal(res.data);
  });
};

export const GetUsersById = (setVal: any, id: any) => {
  Axios.get(`/usuarios/${id}`).then((res: any) => {
    return setVal(res.data);
  });
};

export const GetNewPassword = async (
  id: any,
  lastPassword: any,
  password: any,
  setVal: any
) => {
  try {
    const response = await Axios.get(
      `/usuarios/${id}/${lastPassword}/${password}`
    );

    if (response.status == 200) {
      alert("Senha alterada com sucesso!!");
    }

    setVal(response);
  } catch (err) {
    alert('Erro ao tentar trocar senha!!')
    setVal([
      {
        erro: err,
      },
    ]);
    throw err;
  }
};

export const GetSuppliersByUnidadeId = (setVal: any, id: any) => {
  Axios.get(`/fornecedor-unidade/${id}`).then((res: any) => {
    setVal(res.data);
  });
};

export const PostPublishedNce = async (
  login: any,
  idNce: any,
  comentario: any,
  showError: any
) => {
  try {
    if(comentario.length === 0) comentario = 'default'
    return await Axios.post(`/nce/publishnce/${login}/${idNce}/${comentario}`);
  } catch (err: any) {
    console.log('Error:', err)
    if (err.response && err.response.status) {
      showError(`Http Error: ${err.response.status}`);
    } else if (err.message) {
      showError(`Http Error: ${err.message}`);
    } else {
      showError(`Erro Inesperado ao publicar NCE ${err.status}`);
    }
  }
};

export const DeleteNce = async (
  idNce: any,
  showError: any
) => {
  try{ 
    const login = secureLocalStorage.getItem('login')

    async function executeRequests() {
      try {
        const req1 = Axios.delete(`/dados-do-produto/${idNce}/${login}`);
        const req2 = Axios.delete(`/nce/${idNce}`);

        await Promise.all([req1, req2]).then(() => {
          toast.success(`NCE ${idNce} excluída com sucesso!`, {
            position: toast.POSITION.TOP_CENTER,
          });

          try {
            const deleteFromAllTable = axios.all([
              Axios.delete(`/descricao-nce-ae/${idNce}/${login}`),
              Axios.delete(`/disposicao-do-produto/${idNce}/${login}`),
              Axios.delete(`/rep-lista-nc/${idNce}`),
              Axios.delete(`/acessos/${idNce}`),
              Axios.delete(`/workflow-eventos/${idNce}`),
              Axios.delete(`/acoes-imediatas-de-contencao/${idNce}/${login}`),
              Axios.delete(`/brainstorming/${idNce}/${login}`),
              Axios.delete(`/causa-e-efeito/${idNce}/${login}`),
              Axios.delete(`/cinco-porques/${idNce}/${login}`),
              Axios.delete(`/plano-de-acao/${idNce}/${login}`),
              Axios.delete(`/verificacao-de-eficacia/${idNce}/${login}`),
              Axios.delete(`/imagens-nce-ae/${idNce}/${login}`)
            ])
          } catch (err: any) {
            showError(err.response.data.mensagem);
          }
        });
      } catch (error: any) {
        console.log('Erro: ', error.message);
      }
    }
    executeRequests();
  } catch (err: any) {
    showError(err.response.data.mensagem);
  }
};


export const PostNCE = async (
  ObjNce: any,
  showError: any
) => {
  try {
    const res = await Axios.post(`/nce/savence`, {
      ...ObjNce
    });
    return res;
  } catch (err: any) {
    console.log('Error:', err)
    if (err.response && err.response.status) {
      showError(`Http Error: ${err.response.status}`);
    } else if (err.message) {
      showError(`Http Error: ${err.message}`);
    } else {
      showError(`Erro inesperado ao postar NCE ${err.status}`);
    }
  }
};

export const VoltarEtapaWorkflow = async (LOGIN: string, ID_NCE: string, ID_EVENTO: string, showError: any) => {
  try {
    return await Axios.post(`/nce/voltaretapaworkflow/${LOGIN}/${ID_NCE}/${ID_EVENTO}`)
  } catch (err: any) {
    console.log('Error:', err)
    if (err.response && err.response.status) {
      showError(`Http Error: ${err.response.status}`);
    } else if (err.message) {
      showError(`Http Error: ${err.message}`);
    } else {
      showError(`Erro inesperado ao voltar etapa da NCE ${err.status}`);
    }
  }
 
}

