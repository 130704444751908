import React, { useCallback, useMemo, useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { HiTable } from "react-icons/hi";
import { FaIndustry } from "react-icons/fa";
import "../../ui/components/mensal/mensal.css";
import { GetMensal, GetMensalByForn } from "../../data/services";
import { GetYearsArry } from "../../helpers/helpers";
import { useAuth } from "../../context/auth";

const Mensal: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [openT, setOpenT] = useState(false);
  const [openSede, setOpenSede] = useState(false);
  const [mensal, setMensal] = useState([]);
  const [embalagem, setEmbalagem] = useState("");
  const [dataMensal, setDataMensal] = useState<any>([]);
  const [dataForn, setDataForn] = useState<any>([]);
  const [year, setYear] = useState<any>([]);
  const [anosPesquisa, setAnosPesquisa] = React.useState<number[]>([])

  const openTable = useCallback(
    (item: any, open: boolean) => {
      item.open = open;

      const filterALLUnidades = dataMensal?.filter((dm: any) => {
        return (
          dm?.fornecedor_unidade_descricao ===
          item?.fornecedor_unidade_descricao
        );
      });

      item.data = filterALLUnidades;
    },
    [dataMensal]
  );

  const openAllSede = useCallback(
    (item: any, open: boolean) => {
      item.openSede = open;

      const filterUnidades = dataMensal?.filter((dm: any) => {
        return dm?.fornecedor_descricao === item?.fornecedor_descricao;
      });

      function getObjectsWithDifferentFornecedorDescricaoUnidade(
        array: { id: number; fornecedor_unidade_descricao: string }[]
      ): { id: number; fornecedor_unidade_descricao: string }[] {
        const uniqueFornecedorDescricoes = Array.from(
          new Set(array.map((obj) => obj.fornecedor_unidade_descricao))
        );
        const objectsWithDifferentFornecedorDescricao: {
          id: number;
          fornecedor_unidade_descricao: string;
        }[] = [];
        uniqueFornecedorDescricoes.forEach((descricao) => {
          const objects = array.filter(
            (obj) => obj.fornecedor_unidade_descricao === descricao
          );
          if (objects.length > 1) {
            let haveDifferent = false;
            for (let i = 0; i < objects.length - 1; i++) {
              for (let j = i + 1; j < objects.length; j++) {
                if (
                  objects[i].fornecedor_unidade_descricao !==
                  objects[j].fornecedor_unidade_descricao
                ) {
                  haveDifferent = true;
                  break;
                }
              }
              if (haveDifferent) {
                objectsWithDifferentFornecedorDescricao.push(objects[i]);
                break;
              }
            }
            if (!haveDifferent) {
              objectsWithDifferentFornecedorDescricao.push(objects[0]);
            }
          } else {
            objectsWithDifferentFornecedorDescricao.push(...objects);
          }
        });
        return objectsWithDifferentFornecedorDescricao;
      }

      const unidadesForn =
        getObjectsWithDifferentFornecedorDescricaoUnidade(filterUnidades);

      item.unidades = unidadesForn;
    },
    [dataMensal]
  );

  const {user: {profile, idFabrica}} = useAuth()

  React.useEffect(() => {
    profile === "usuarioFornecedor"
      ? GetMensalByForn(setMensal, idFabrica)
      : GetMensal(setMensal);
    setAnosPesquisa(GetYearsArry(new Date().getFullYear(), 7));
  }, []);

  const SearchItem = React.useCallback(() => {

    const filter = mensal.filter((item: any) => {
      return item.tipo_embalagem === embalagem;
    });

    const filterByYear = filter.filter((item: any) => {
      return item.ano === year;
    });

    setDataMensal(filterByYear);

    function getObjectsWithDifferentFornecedorDescricao(
      array: { id: number; fornecedor_descricao: string }[]
    ): { id: number; fornecedor_descricao: string }[] {
      const uniqueFornecedorDescricoes = Array.from(
        new Set(array.map((obj) => obj.fornecedor_descricao))
      );
      const objectsWithDifferentFornecedorDescricao: {
        id: number;
        fornecedor_descricao: string;
      }[] = [];
      uniqueFornecedorDescricoes.forEach((descricao) => {
        const objects = array.filter(
          (obj) => obj.fornecedor_descricao === descricao
        );
        if (objects.length > 1) {
          let haveDifferent = false;
          for (let i = 0; i < objects.length - 1; i++) {
            for (let j = i + 1; j < objects.length; j++) {
              if (
                objects[i].fornecedor_descricao !==
                objects[j].fornecedor_descricao
              ) {
                haveDifferent = true;
                break;
              }
            }
            if (haveDifferent) {
              objectsWithDifferentFornecedorDescricao.push(objects[i]);
              break;
            }
          }
          if (!haveDifferent) {
            objectsWithDifferentFornecedorDescricao.push(objects[0]);
          }
        } else {
          objectsWithDifferentFornecedorDescricao.push(...objects);
        }
      });
      return objectsWithDifferentFornecedorDescricao;
    }

    const res = getObjectsWithDifferentFornecedorDescricao(filterByYear);

    setDataForn(res);
    setOpen(true);
  }, [embalagem, mensal, year]);
  

  function getDifferentsEmbalagens(
    array: { id: number; tipo_embalagem: string }[]
  ): { id: number; tipo_embalagem: string }[] {
    const uniqueFornecedorDescricoes = Array.from(
      new Set(array.map((obj) => obj.tipo_embalagem))
    );
    const objectsWithDifferentFornecedorDescricao: {
      id: number;
      tipo_embalagem: string;
    }[] = [];
    uniqueFornecedorDescricoes.forEach((descricao) => {
      const objects = array.filter(
        (obj) => obj.tipo_embalagem === descricao
      );
      if (objects.length > 1) {
        let haveDifferent = false;
        for (let i = 0; i < objects.length - 1; i++) {
          for (let j = i + 1; j < objects.length; j++) {
            if (
              objects[i].tipo_embalagem !==
              objects[j].tipo_embalagem
            ) {
              haveDifferent = true;
              break;
            }
          }
          if (haveDifferent) {
            objectsWithDifferentFornecedorDescricao.push(objects[i]);
            break;
          }
        }
        if (!haveDifferent) {
          objectsWithDifferentFornecedorDescricao.push(objects[0]);
        }
      } else {
        objectsWithDifferentFornecedorDescricao.push(...objects);
      }
    });
    return objectsWithDifferentFornecedorDescricao;
  }

  const embalagens = getDifferentsEmbalagens(mensal); 

  return (
    <div>
      <div>
        <h1>PQMF - Mensal</h1>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          border: "1px solid #d4d4d5",
          padding: "10px 15px",
          borderRadius: 4,
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "80%" }}>

          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label style={{ marginRight: 15, color: "#555555", width: "100%" }}>Ano</label>
            <select
              id="statusSelect"
              className="select-defect"
              style={{ outline: "none", width: "100%" }}
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="0">Selecione</option>
              {anosPesquisa.map((item: any) => {
                return (
                  <option value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", width: "100%", marginLeft: "20px" }}
          >
            <label style={{ marginRight: 15, color: "#555555", width: "100%" }}>
              Grupo de Embalagem
            </label>
            <select
              id="statusSelect"
              name=""
              className="select-defect"
              style={{ outline: "none", width: "70%" }}
              onChange={(e) => setEmbalagem(e.target.value)}
            >
              <option>Selecione</option>
              {embalagens.map((item: any) => {
                return (
                  <option value={item.tipo_embalagem}>
                    {item.tipo_embalagem}
                  </option>
                );
              })}
            </select>
          </div>

        </div>

        <div style={{ display: "flex", height: 40, justifyContent: "end" }}>
          <button className="button-defect-search" onClick={() => SearchItem()} disabled={(year == '0' || !embalagem  ) ? true : false}>
            <BsSearch style={{ marginRight: 10 }} />
            Pesquisar
          </button>
        </div>

      </div>
      {open && (
        <div className="content-info-mensal">
          <div className="container-info-mensal">
            <div
              style={{
                background: "#f3f4f5",
                border: "1px solid #d4d4d5",
                padding: "1rem 1rem",
                borderRadius: 4,
              }}
            >
              <h1
                style={{
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  margin: 0,
                }}
              >
                <HiTable
                  color="rgba(163, 142, 23, 0.8)"
                  size={25}
                  style={{ marginRight: 10 }}
                />
                Fornecedor | Planta | Indicador
              </h1>
            </div>
            <div
              style={{
                border: "1px solid #d4d4d5",
                borderRadius: 4,
                marginTop: 15,
              }}
            >
              <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                {dataForn.map((item: any) => {
                  return (
                    <li className="list-style-mensal">
                      <div
                        className="title-name-sede"
                        onClick={() => {
                          setOpenSede(!openSede);
                          openAllSede(
                            item,
                            item.openSede ? !item.openSede : true
                          );
                        }}
                      >
                        <FaIndustry
                          color="rgba(163, 142, 23, 0.8)"
                          style={{ marginRight: 10 }}
                        />
                        {item.fornecedor_descricao}
                      </div>

                      {item.openSede && (
                        <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                          {item.unidades.map((sd: any) => {
                            return (
                              <li className="list-sede">
                                <p
                                  className="name-sede"
                                  onClick={() => {
                                    setOpenT(!openT);
                                    openTable(sd, sd.open ? !sd.open : true);
                                  }}
                                >
                                  <li>{sd.fornecedor_unidade_descricao}</li>
                                </p>
                                {sd.open && (
                                  <div>
                                    <table className="table-sede">
                                      <thead className="table-head">
                                        <tr>
                                          <th>Indicador</th>
                                          {sd.data.map((item: any) => {
                                            return (
                                              <th className="header-sede">
                                                {item.mes_abv}
                                              </th>
                                            );
                                          })}
                                        </tr>
                                      </thead>

                                      <tbody className="table-body">
                                        <tr>
                                          <td>
                                            <div className="pqmf">PQMF</div>
                                          </td>
                                          {sd.data.map((info: any) => {
                                            return <td>{info.pqmf}</td>;
                                          })}
                                        </tr>
                                        <tr>
                                          <td>Performance de Qualidade</td>
                                          {sd.data.map((info: any) => {
                                            return <td>{info.pq}</td>;
                                          })}
                                        </tr>
                                        <tr>
                                          <td>Devolutivas de NCEs</td>
                                          {sd.data.map((info: any) => {
                                            return <td>{info.de}</td>;
                                          })}
                                        </tr>
                                        <tr>
                                          <td>Qualificação</td>
                                          {sd.data.map((info: any) => {
                                            return <td>{info.qa}</td>;
                                          })}
                                        </tr>
                                        <tr>
                                          <td>Logística</td>
                                          {sd.data.map((info: any) => {
                                            return <td>{info.lg}</td>;
                                          })}
                                        </tr>
                                        <tr>
                                          <td>Comercial</td>
                                          {sd.data.map((info: any) => {
                                            return <td>{info.cm}</td>;
                                          })}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Mensal;
