import React from "react";
import { BsPersonCircle } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./header.css";
import secureLocalStorage from "react-secure-storage";

const HeaderUser = () => {
  const [open, setOpen] = React.useState<boolean>(false);

  const logout = React.useCallback(() => {
    secureLocalStorage.clear();
    window.location.href = "/";
  }, []);

  const alterarSenha = React.useCallback(() => {
    window.location.href='/alterar-senha';
  }, []);

  const User: any = secureLocalStorage.getItem("username");
  const Profile: any = secureLocalStorage.getItem("profile");

  return (
    <div className="container-header">
      <div>
        <div className="content-header-info">
          <BsPersonCircle size={25} />
          <p style={{ maxWidth: 100, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{User}</p>
          {!open && (
            <IoIosArrowDown
              cursor="pointer"
              size={20}
              onBlur={() => setOpen(false)}
              onClick={() => setOpen(!open)}
            />
          )}
          {open && (
            <IoIosArrowUp
              cursor="pointer"
              size={20}
              onClick={() => setOpen(!open)}
            />
          )}
        </div>
        {open && (
          <div className="content-options">
            <button onClick={logout}>Sair</button>
            {
              Profile === 'usuarioFornecedor' &&
            <button onClick={alterarSenha}>Alterar Senha</button>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderUser;
