import React, { useCallback, useMemo, useEffect } from "react";
import "../../ui/components/admin/admin.css";
import SignIn from "../../ui/components/admin/signIn";
import {
  GetUsers,
  DeleteUser,
  GetMaterials,
  GetSuppliers,
  GetSuppliersByUnity,
  DeleteMaterial,
  DeleteSupplier,
  DeleteFeriado,
  GetDataFeriado,
  DeleteDefect,
  GetDefects,
  GetEmbalagens,
  GetSuppliersByEmbalagens,
  DeleteEmbalagens,
  GetUsersUnidade,
  GetProduto,
  DeleteProduct,
} from "../../data/services";
import { useAuth } from "../../context/auth";
import { AiOutlineClose } from "react-icons/ai";
import secureLocalStorage from "react-secure-storage";

const AdminList = () => {
  const { user } = useAuth();
  const profile = user.profile;

  if (profile === "usuarioFornecedor") window.location.href = "/home";

  const [loading, setLoading] = React.useState<any>(false);
  const [deleteData, setDeleteData] = React.useState<any>(false);
  const [content, setContent] = React.useState<any>([]);
  const [open, setOpen] = React.useState<any>({
    open: false,
    method: "",
  });
  const [info, setInfo] = React.useState<any>();
  const [option, setOption] = React.useState<any>([]);
  const [fornUnity, setFornUnity] = React.useState([]);
  const [details, setDetails] = React.useState<boolean>(false);
  const [deleteOn, setDeleteOn] = React.useState<boolean>(false);
  const [embalagens, setEmbalagens] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [fornEmbalagens, setFornEmbalagens] = React.useState([]);
  const [defects, setDefects] = React.useState<any>();
  const [unidadesByUsers, setUnidadesByUsers] = React.useState<any>();
  const [detailItem, setDetailItem] = React.useState<any>();
  const [dataDefect, setDataDefect] = React.useState<any>();

  const token: any = secureLocalStorage.getItem("access_token");

  const UnidadeAdmin = "adminUnidade";
  const SuperAdmin = "superAdmin";

  const adm = profile === SuperAdmin || profile === UnidadeAdmin;
  // const adm = true

  const idFabrica: any = secureLocalStorage.getItem("idFabrica");
  const idUser: any = secureLocalStorage.getItem("idUser");

  if (!token) {
    window.location.href = "/";
  }

  const type = window.location.href.includes("users")
    ? "Users"
    : window.location.href.includes("product")
    ? "Product"
    : window.location.href.includes("suppliers")
    ? "Fornecedores"
    : window.location.href.includes("defeitos")
    ? "Defeitos"
    : window.location.href.includes("embalagens")
    ? "Embalagens"
    : "Feriado";

  function order(a: any, b: any) {
    if (a.nomeUsuario > b.nomeUsuario) {
      return 1;
    }
    if (a.nomeUsuario < b.nomeUsuario) {
      return -1;
    }
    return 0;
  }

  const items = [
    { name: "Edward", value: 21 },
    { name: "Sharpe", value: 37 },
    { name: "And", value: 45 },
    { name: "The", value: -12 },
    { name: "Magnetic" },
    { name: "Zeros", value: 37 },
  ];
  items.sort(function (a, b) {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  React.useEffect(() => {
    if (type === "Users") {
      GetUsers(setOption);
      GetSuppliersByUnity(setUsers);
      GetUsersUnidade(setUnidadesByUsers);
      setOption(option.sort(order));
    }
    if (type === "Product") {
      GetEmbalagens(setEmbalagens);
      GetProduto(setOption);
    }

    if (type === "Fornecedores") {
      GetSuppliers(setContent);
      GetSuppliersByUnity(setFornUnity);
      GetEmbalagens(setEmbalagens);
      GetSuppliersByEmbalagens(setFornEmbalagens);
    }

    if (type === "Feriado") {
      GetDataFeriado(setOption);
    }

    if (type === "Defeitos") {
      GetDefects(setOption);
    }

    if (type === "Embalagens") {
      GetEmbalagens(setOption);
      GetDefects(setDefects);
    }
  }, [type]);

  const Delete = (id: any) => {
    if (type === "Users") {
      DeleteUser(id);
    }
    if (type === "Product") {
      DeleteProduct(id);
    }

    if (type === "Fornecedores") {
      DeleteSupplier(id);
    }

    if (type === "Feriado") {
      DeleteFeriado(id);
    }

    if (type === "Defeitos") {
      DeleteDefect(id);
    }

    if (type === "Embalagens") {
      DeleteEmbalagens(id);
    }

    setDeleteData(false);
    setDeleteOn(true);
  };

  useEffect(() => {
    if (type === "Fornecedores") {
      const idsEmbalagens = fornEmbalagens.map(
        (item: any) => item.idFornecedor
      );

      const idsUnity = fornUnity.map((item: any) => item.idFornecedor);

      const FornsUnitys = content?.filter((a: any) => {
        return idsUnity.includes(a.idFornecedor);
      });

      const idsForn = content?.map((item: any) => item.idFornecedor);

      const Units = fornUnity?.filter((a: any) => {
        return idsForn.includes(a.idFornecedor); 
      });
      FornsUnitys?.filter((item: any) => {
        return (
          (item.unidades = Units?.filter(
            (un: any) => item.idFornecedor === un.idFornecedor
          )),
          (item.embalagens = fornEmbalagens?.filter(
            (em: any) => item.idFornecedor == em.idFornecedor
          ))
        );
      });

      setOption(FornsUnitys);
    }
  }, [content, embalagens, fornEmbalagens, fornUnity]);

  const ListColumns = useMemo(() => {
    return type === "Product" ? (
      <>
        <td className="listProperty">Tipo de produto</td>
        <td className="listProperty">Tipo de material</td>
        {adm && <td className="listProperty">Ações</td>}
      </>
    ) : type === "Users" ? (
      <>
        <table className="table" style={{ marginLeft: '30px' }}>
          <thead style={{
            textAlign: 'center'
          }}>
            <td className="col-md-2">Nome de usuário</td>
            <td className="col-md-2">Tipo</td>
            <td className="col-md-2">Login</td>
            <td className="col-md-2">Perfil de acesso</td>
            {/* <td className="col-md-2">E-mail</td> */}
            {adm && <td className="col-md-2">Ações</td>}
          </thead>
        </table>
      </>
    ) : type === "Fornecedores" ? (
      <>
        <td className="listProperty">Fornecedor</td>
        {adm && <td className="listProperty">Ações</td>}
      </>
    ) : type === "Defeitos" ? (
      <>
        <td className="listProperty">Id</td>
        <td className="listProperty">Nome do defeito</td>
        {adm && <td className="listProperty">Ações</td>}
      </>
    ) : type === "Embalagens" ? (
      <>
        <td className="listProperty">Id</td>
        <td className="listProperty">Nome da embalagem</td>
        {adm && <td className="listProperty">Ações</td>}
      </>
    ) : (
      <>
        <td className="listProperty">Data do feriado</td>
        <td className="listProperty">Descrição</td>
        {adm && <td className="listProperty">Ações</td>}
      </>
    );
  }, []);

  const OpenDefect = useCallback((item: any, open: boolean) => {
    item.open = open;
    setDataDefect(item);
  }, []);

  const OpenDetails = useCallback((item: any, open: boolean) => {
    item.open = open;
    setDetailItem(item);
  }, []);

  const List = useMemo(() => {
    return type === "Product" ? (
      <>
        {option
          ?.sort(function (a: any, b: any) {
            if (a.produto > b.produto) {
              return 1;
            }
            if (a.produto < b.produto) {
              return -1;
            }
            return 0;
          })
          .map((item: any) => {
            return (
              <tr
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 5,
                  borderBottom: "1px solid #dddddd",
                }}
              >
                <td className="listTd">{item.produto ? item.produto : "~~"}</td>
                <td className="listTd">
                  {item.material ? item.material : "~~"}
                </td>
                {/* <td className="listProperty">Tipo de defeito</td> */}
                <div style={{ display: "flex", flex: 1, marginLeft: 3 }}>
                  {/* removido pois cada produto está vinculado a um idProduto, se editar teria que vir todas embalagens */}
                  {/* <button
                    className="editBtn"
                    onClick={() => {
                      setOpen((prev: any) => ({
                        ...prev,
                        open: true,
                        method: "putch",
                      }));
                      setInfo(item);
                    }}
                  >
                    Editar
                  </button> */}
                  <button
                    className="deleteUserBtn"
                    onClick={() => {
                      setDeleteData((prev: any) => ({
                        ...prev,
                        open: true,
                        id: item.idProduto,
                      }));
                      setInfo(item);
                    }}
                  >
                    Deletar
                  </button>
                </div>
              </tr>
            );
          })}
      </>
    ) : type === "Users" ? (
      <>
        {option
          .sort(function (a: any, b: any) {
            if (a.nomeUsuario > b.nomeUsuario) {
              return 1;
            }
            if (a.nomeUsuario < b.nomeUsuario) {
              return -1;
            }
            return 0;
          })
          .map((item: any) => {
            return (
              <>
                <div className="row"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    padding: 5,
                    borderBottom: "1px solid #dddddd",
                  }}>
                  <tr style={{ display: "flex" }}>
                    <td  className="listTd">{item.nomeUsuario}</td>
                    <td  className="listTd">{item.tipo}</td>
                    <td  className="listTd">{item.login}</td>
                    <td  className="listTd">{item?.perfilAcesso ? item?.perfilAcesso : "~~"}</td>
                    {/* <td  className="listTd">{item.email}</td> */}
                    {adm && (
                      <div style={{display: "flex", justifyContent: "flex-end", columnGap: "5px", marginLeft: "10px"}}>
                        <button
                          // className="editBtn"
                          className="btn btn-light"
                          onClick={() => {
                            setOpen((prev: any) => ({
                              ...prev,
                              open: true,
                              method: "putch",
                            }));
                            setInfo(item);
                          }}
                          style={{ float: 'left', margin: '2' }}
                        >
                          Editar
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            setDeleteData((prev: any) => ({
                              ...prev,
                              open: true,
                              id: item?.idUsuario,
                            }));
                            setInfo(item);
                          }}
                          style={{ float: 'right' }}
                        >
                          Deletar
                        </button>
                      </div>
                    )}
                  </tr>
                </div>
              </>
            );
          })}
      </>
    ) : type === "Fornecedores" ? (
      <>
        {content
          ?.sort(function (a: any, b: any) {
            if (a.fornecedorDescricao > b.fornecedorDescricao) {
              return 1;
            }
            if (a.fornecedorDescricao < b.fornecedorDescricao) {
              return -1;
            }
            return 0;
          })
          .map((item: any) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  padding: 5,
                  borderBottom: "1px solid #dddddd",
                }}
              >
                <tr style={{ display: "flex", width: "100%" }}>
                  <td className="listTd">{item.fornecedorDescricao ?? "~~"}</td>
                  {adm && (
                    <div style={{ display: "flex", flex: 1, marginLeft: 3 }}>
                      <button
                        className="editBtn"
                        onClick={() => {
                          setOpen((prev: any) => ({
                            ...prev,
                            open: true,
                            method: "putch",
                          }));
                          setInfo(item);
                        }}
                      >
                        Editar
                      </button>
                      <button
                        className="deleteUserBtn"
                        onClick={() => {
                          setDeleteData((prev: any) => ({
                            ...prev,
                            open: true,
                            id: item?.idFornecedor,
                          }));
                          setInfo(item);
                        }}
                      >
                        Deletar
                      </button>
                      <button
                        className="deleteUserBtn"
                        onClick={() => {
                          setDetails(!details);
                          OpenDetails(item, item.open ? !item.open : true);
                        }}
                      >
                        Detalhes
                      </button>
                    </div>
                  )}
                </tr>
              </div>
            );
          })}
      </>
    ) : type === "Defeitos" ? (
      <>
        {option
          ?.sort(function (a: any, b: any) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          })
          .map((item: any) => {
            return (
              <tr
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 5,
                  borderBottom: "1px solid #dddddd",
                }}
              >
                <td className="listTd">{item.idTipoDefeito ?? "~~"}</td>
                <td className="listTd">{item.tipoDefeito ?? "~~"}</td>
                {/* <td className="listProperty">Tipo de defeito</td> */}
                {adm && (
                  <div style={{ display: "flex", flex: 1, marginLeft: 3 }}>
                    <button
                      className="editBtn"
                      onClick={() => {
                        setOpen((prev: any) => ({
                          ...prev,
                          open: true,
                          method: "putch",
                        }));
                        setInfo(item);
                      }}
                    >
                      Editar
                    </button>
                    <button
                      className="deleteUserBtn"
                      onClick={() => {
                        setDeleteData((prev: any) => ({
                          ...prev,
                          open: true,
                          id: item.idTipoDefeito,
                        }));
                        setInfo(item);
                      }}
                    >
                      Deletar
                    </button>
                  </div>
                )}
              </tr>
            );
          })}
      </>
    ) : type === "Embalagens" ? (
      <>
        {option
          ?.sort(function (a: any, b: any) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          })
          .map((item: any) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  padding: 5,
                  borderBottom: "1px solid #dddddd",
                }}
              >
                <tr style={{ display: "flex", width: "100%" }}>
                  <td className="listTd">{item.idTipoEmbalagem}</td>
                  <td className="listTd">{item.tipoEmbalagem}</td>
                  {adm && (
                    <div style={{ display: "flex", flex: 1, marginLeft: 3 }}>
                      <button
                        className="editBtn"
                        onClick={() => {
                          setOpen((prev: any) => ({
                            ...prev,
                            open: true,
                            method: "putch",
                          }));
                          setInfo(item);
                        }}
                      >
                        Editar
                      </button>
                      <button
                        className="deleteUserBtn"
                        onClick={() => {
                          setDeleteData((prev: any) => ({
                            ...prev,
                            open: true,
                            id: item.idTipoEmbalagem,
                          }));
                          setInfo(item);
                        }}
                      >
                        Deletar
                      </button>
                      <button
                        className="deleteUserBtn"
                        onClick={() => {
                          setDetails(!details);
                          OpenDefect(item, item.open ? !item.open : true);
                        }}
                      >
                        Detalhes
                      </button>
                    </div>
                  )}
                </tr>
              </div>
            );
          })}
      </>
    ) : (
      <>
        {option
          .sort(function (a: any, b: any) {
            if (a.mes > b.mes) {
              return 1;
            }
            if (a.mes < b.mes) {
              return -1;
            }

            if (a.dia > b.dia) {
              return 1;
            }
            if (a.dia < b.dia) {
              return -1;
            }
            return 0;
          })
          .map((item: any) => {
            return (
              <>
                <div className="row"
                  style={{
                    textAlign: 'center'
                  }}>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="col-md-3">
                          {`${item.dia < 10 ? "0" + item.dia : item.dia}/${item.mes < 10 ? "0" + item.mes : item.mes}`}
                        </td>
                        <td className="col-md-6">
                          {item.descricaoFeriado}
                        </td>
                        <td className="col-md-3">
                          <div style={{ display: "flex", justifyContent: "flex-end", columnGap: "5px" }}>
                            <button
                              // className="editBtn"
                              className="btn btn-light"
                              onClick={() => {
                                setOpen((prev: any) => ({
                                  ...prev,
                                  open: true,
                                  method: "putch",
                                }));
                                setInfo(item);
                              }}
                              style={{ float: 'left', margin: '2' }}
                            >
                              Editar
                            </button>
                            <button
                              // className="deleteUserBtn"
                              className="btn btn-danger"
                              onClick={() => {
                                setDeleteData((prev: any) => ({
                                  ...prev,
                                  open: true,
                                  id: item?.idFeriado,
                                }));
                                setInfo(item);
                              }}
                              style={{ float: 'right' }}
                            >
                              Deletar
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            );
          })}
      </>
    );
  }, [Delete, option, type]);

  return (
    <>
      {!open.open ? (
        <div className="DivGeneral">
          {loading ? (
            <img className="Loading" src="../assets/loading-buffering.gif" />
          ) : (
            <>
              {deleteData?.open ? (
                <div className="delete">
                  <h3 style={{ textAlign: 'center' }}>
                    Tem certeza que deseja excluir <br></br> {" "}
                    {type === "Users"
                      ? `o usuário: ${info.nomeUsuario} `
                      : type === "Feriado"
                        ? `o feriado: ${info.descricaoFeriado} `
                        : type === "Fornecedores"
                          ? `o fornecedor: ${info.fornecedorDescricao} `
                          : type === "Embalagens"
                            ? `a embalagem: ${info.tipoEmbalagem} `
                            : type === "Defeitos"
                              ? `o defeito: ${info.tipoDefeito} `
                              : `o produto: ${info.produto} `}
                    <br></br> permanentemente?
                  </h3>
                  <div className="div-button-confirm">
                    <button
                      style={{
                        background: "#d83123",
                        borderRadius: 4,
                        color: "white",
                      }}
                      className="button-confirm"
                      onClick={() => {
                        Delete(deleteData.id);
                      }}
                    >
                      Deletar
                    </button>
                    <button
                      className="button-confirm"
                      onClick={() => {
                        setDeleteData(false);
                      }}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              ) : deleteOn ? (
                <div className="delete">
                  <h3 style={{ textAlign: 'center' }}>
                    {" "}
                    {type === "Users"
                      ? `O usuário: ${info.nomeUsuario} foi excluído com sucesso!`
                      : type === "Feriado"
                        ? `O feriado: ${info.descricaoFeriado} foi excluído com sucesso!`
                        : type === "Fornecedores"
                          ? `O fornecedor: ${info.fornecedorDescricao} foi excluído com sucesso!`
                          : type === "Embalagens"
                            ? `A embalagem: \n ${info.tipoEmbalagem} foi excluída com sucesso!`
                            : type === "Defeitos"
                              ? `O defeito: ${info.tipoDefeito} foi excluído com sucesso!`
                              : `O produto: ${info.produto} foi excluído com sucesso!`}
                  </h3>
                  <div className="div-button-confirm">
                    <button
                      style={{
                        background: "#d83123",
                        borderRadius: 4,
                        color: "white",
                      }}
                      className="button-confirm"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="modalSignUp">
                    <div className="content-header-button-and-title">
                      <h1>
                        {type === "Users"
                          ? "Usuários"
                          : type === "Feriado"
                          ? "Feriados"
                          : type === "Fornecedores"
                          ? "Fornecedores"
                          : type === "Defeitos"
                          ? "Defeitos"
                          : type === "Embalagens"
                          ? "Embalagens"
                          : type === "Product"
                          ? "Produtos"
                          : ""}
                      </h1>

                      {adm && (
                        <button
                          className="BtnClean"
                          onClick={() =>
                            setOpen((prev: any) => ({
                              ...prev,
                              open: true,
                              method: "post",
                            }))
                          }
                        >
                          Adicionar
                        </button>
                      )}
                    </div>

                    <div className="contentList">{ListColumns}</div>
                    <div className="content">
                      <div className="containerListInfo">
                        {option.length > 0 ? (
                          <>{List}</>
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            <h1 style={{ margin: "35px 0", fontSize: 28 }}>
                              Não há registros aqui
                            </h1>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {detailItem && detailItem.open && (
                <div className="content-edit">
                  <div className="info-content-details">
                    <div style={{ position: 'relative' }}>
                      <AiOutlineClose style={{ cursor: 'pointer', float: 'right' }}
                        size={25}
                        onClick={() => setDetailItem(false)}
                      >
                      </AiOutlineClose>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <div className="content-details" style={{ justifyContent: 'center' }}>
                        <h1>Unidades</h1>
                        {detailItem?.unidades?.map((unit: any) => {
                          return (
                            unit.deleted === 0 &&
                            <div style={{ marginBottom: '10px', width: '170px', fontSize: '12px' }}>
                              {unit.fornecedorUnidadeDescricao}
                            </div>

                          );
                        })}
                      </div>
                      <div style={{ fontWeight: 500, position: 'relative', bottom: '10px', fontSize: '16px' }}>{detailItem.fornecedorDescricao}</div>

                      <div className="content-details" >
                        <h1>Embalagens</h1>
                        {detailItem?.embalagens?.map((em: any) => {
                          return (
                            em.deleted === 0 &&
                            <div style={{ marginBottom: '10px', width: '170px', fontSize: '12px' }}>
                              {em.categoria}
                            </div>

                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {dataDefect && dataDefect.open && (
                <div
                  className="content-edit">
                  <div className="info-content-details">
                    <div className="content-details">
                      <AiOutlineClose style={{ cursor: 'pointer', position: 'relative', left: '45%' }}
                        size={25}
                        onClick={() => setDataDefect(false)}
                      >
                      </AiOutlineClose>
                      <h1>Defeitos</h1>
                      {defects.map((df: any) => {
                        return (
                          <div style={{ fontSize: '12px' }}>
                            {df.idTipoEmbalagem == dataDefect.idTipoEmbalagem &&
                              df.tipoDefeito}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <SignIn
          method={open.method}
          type={type}
          unidades={type === "Users" ? unidadesByUsers : []}
          embalagens={
            type === "Fornecedores" || type === "Product" ? embalagens : type === "Embalagens" ? option : []
          }
          users={type === "Users" ? users : []}
          defeitos={type === "Embalagens" ? defects : []}
          info={open?.method === "putch" ? info : []}
          setDefects={type === "Embalagens" && setDefects}
          setOpen={setOpen}
        />
      )}
    </>
  );
};

export default AdminList;
