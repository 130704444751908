import React, { useRef, MouseEvent, useState, useCallback } from "react";
import "../../ui/components/estratificacao-defeitos/estratificacoes.css";
import "../../ui/components/analitico/analitico.css";
import { BsSearch } from "react-icons/bs";
import BarChat from "../../ui/components/grafico";
import { GoGraph } from "react-icons/go";
import { AiFillInfoCircle } from "react-icons/ai";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from "react-chartjs-2";
import type { InteractionItem } from "chart.js";
import { Chart as ChartJS } from "chart.js";
import { Chart, Doughnut } from "react-chartjs-2";
import {
  GetByUnityBySuppliers,
  GetPQMFAnalatico,
  GetPQMFAnalaticoMensal,
  GetPQMFAnaliticoListaNce,
  GetSuppliers,
  GetSuppliersById,
  GetSuppliersByUnity,
} from "../../data/services";
import { match } from "assert";
import { useAuth } from "../../context/auth";
import secureLocalStorage from "react-secure-storage";

const Analitico = () => {
  const token: any = secureLocalStorage.getItem("access_token");

  const [forn, setForn] = useState<any>([]);
  const [units, setUnits] = useState<any>([]);
  const [data, setData] = useState<any>();
  const [grafics, setGrafics] = useState<any>(false);

  const Data: any = [
    {
      comercial: 0,
      devolutiva: 20,
      performance: 30,
      qualificacao: 14,
      logistica: 64,
      pqmf: 70,
      year: 2017,
      mounth: "Janeiro",
    },
    {
      comercial: 15,
      devolutiva: 20,
      pqmf: 70,
      year: 2018,
      mounth: "Fevereiro",
    },
    {
      comercial: 15,
      devolutiva: 20,
      pqmf: 70,
      year: 2019,
      mounth: "Março",
    },
    {
      comercial: 15,
      devolutiva: 20,
      pqmf: 70,
      year: 2020,
      mounth: "Abril",
    },
    {
      comercial: 15,
      devolutiva: 20,
      pqmf: 70,
      year: 2021,
      mounth: "Maio",
    },
    {
      comercial: 15,
      devolutiva: 20,
      pqmf: 70,
      year: 2022,
      mounth: "Junho",
    },
    {
      comercial: 15,
      devolutiva: 20,
      pqmf: 70,
      year: 2023,
      mounth: "Julho",
    },
  ];

  const DataSelect = [
    {
      id: 0,
      nameFantasy: "Ambev",
      locations: ["Bahia", "São Paulo", "Minas gerais", "Salvador"],
    },
    {
      id: 1,
      nameFantasy: "Itaipava",
      locations: ["Bahia", "São Paulo", "Minas gerais", "Salvador"],
    },
    {
      id: 2,
      nameFantasy: "Dell",
      locations: ["Bahia", "São Paulo", "Minas gerais", "Salvador"],
    },
  ];

  const [userData, setUserData] = React.useState<any>();

  const labelsByMouth = Data.map((item: any) => {
    return item.mounth;
  });

  const [year, setYear] = React.useState<number>();
  const [mouth, setMouth] = React.useState<string>();
  const [dataMouth, setDataMouth] = React.useState<any>([]);
  const [dataByMouth, setDataByMouth] = React.useState<any>([]);
  const [nceList, setNceList] = React.useState<any>([]);

  const [fornItem, setFornItem] = React.useState<any>("Selecione");
  const [objectForn, setObjectForn] = React.useState<any>();
  const [disabledButton, setDisabledButton] = React.useState(true)

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right" as const,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const optionsByMouth = {
    responsive: true,
    plugins: {
      legend: {
        position: "right" as const,
      },
      title: {
        display: true,
        text: year?.toString(),
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const optionsDought: any = {
    type: "doughnut",
    aspectRatio: 1,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: mouth,
      },
    },
  };

  const FilterItemByName =
    fornItem &&
    units.filter((item: any) => {
      return item.idFornecedor == fornItem;
    });

  const printElementAtEventYear = (element: InteractionItem[]) => {
    if (!element.length) {
      return;
    }
    const { index } = element[0];
    setYear(userData?.labels[index]);
    getCharYear(userData?.labels[index])
  };

 const getCharYear = (year: number) => {
    if (year) {
      GetPQMFAnalaticoMensal(
        setDataMouth,
        year ?? 2023,
        objectForn?.idForn,
        objectForn?.idUnidade
      );
    }
  }
  const chartRef = useRef(null);

  const HandleChangeYear = (event: any) => {
    const { current: chart } = chartRef;
    if (!chart) {
      return;
    }

    printElementAtEventYear(getElementAtEvent(chart, event));
  };

  const printElementAtEventMouth = (element: InteractionItem[]) => {
    if (!element.length) return;
    const { index } = element[0];
    
    setMouth(dataByMouth.labels[index]);
  };

  const chartRefMouth = useRef(null);

  const ListNces = useCallback(() => {

    const filter = dataMouth.filter((item: any) => {
      return item.mes_abv === mouth;
    });

    GetPQMFAnaliticoListaNce(
      setNceList,
      filter[0]?.id_fechamento,
      objectForn?.idUnidade
    );

  }, [dataMouth, mouth, objectForn?.idUnidade]);

  const HandleChangeMouth = (event: any) => {
    const { current: chartMouth } = chartRefMouth;
    if (!chartMouth) {
      return;
    }

    printElementAtEventMouth(getElementAtEvent(chartMouth, event));
  };

  React.useEffect(() => {

    const labels = dataMouth?.map((item: any) => item.mes_abv);

    const comercial = dataMouth?.map((item: any) => parseInt(item?.cm));
    const logistica = dataMouth?.map((item: any) => parseInt(item?.lg));
    const devolutiva = dataMouth?.map((item: any) => parseInt(item?.de));
    const performance = dataMouth?.map((item: any) => parseInt(item?.pq));
    const qualificacao = dataMouth?.map((item: any) => parseInt(item?.qa));

    setDataByMouth({
      labels: labels,
      datasets: [
        {
          label: "Comercial",
          type: "bar" as const,
          data: comercial,
          backgroundColor: "rgb(90 86 14 / 80%)",
        },
        {
          label: "Logística",
          type: "bar" as const,
          data: logistica,
          backgroundColor: "rgba(163, 142, 23, 0.8)",
        },
        {
          label: "Devolutiva",
          type: "bar" as const,
          data: devolutiva,
          backgroundColor: "brown",
        },
        {
          label: "Performance",
          type: "bar" as const,
          data: performance,
          backgroundColor: "rgb(177 121 0 / 80%)",
        },
        {
          label: "Qualificação",
          type: "bar" as const,
          data: qualificacao,
          backgroundColor: "yellow",
        },
      ],
    });
  }, [dataMouth, year]);

  const ObjDataByMouthFilter = dataMouth?.filter((item: any) => {
    return item.mes_abv === mouth;
  });

  const newArr = [
    ObjDataByMouthFilter[0]?.cm,
    ObjDataByMouthFilter[0]?.de,
    ObjDataByMouthFilter[0]?.pq,
    ObjDataByMouthFilter[0]?.lg,
    ObjDataByMouthFilter[0]?.qa,
    ObjDataByMouthFilter[0]?.pqmf,
  ];

  const labels = [
    ObjDataByMouthFilter[0]?.cm > 0 ? "Comercial" : "",
    ObjDataByMouthFilter[0]?.de > 0 ? "Devolutiva" : "",
    ObjDataByMouthFilter[0]?.pq > 0 ? "Performance" : "",
    ObjDataByMouthFilter[0]?.lg > 0 ? "Logistica" : "",
    ObjDataByMouthFilter[0]?.qa > 0 ? "Qualificação" : "",
    ObjDataByMouthFilter[0]?.pqmf > 0 ? "PQMF" : "",
  ];

  const [dought, setDought] = React.useState<any>({
    labels: [      
      "PQMF > Comercial",
      "PQMF > Devolutiva",
      "PQMF > Performance",
      "PQMF > Logistica",
      "PQMF > Qualificação",
      "PQMF",
    ],
  });

  React.useEffect(() => {
    setDought((prev: any) => ({
      ...prev,
      datasets: [
        {
          label: "valor",
          data: newArr,
          backgroundColor: "rgba(163, 142, 23, 0.8)",
          borderColor: ["rgba(163, 142, 23, 0.8)"],
          borderWidth: 1,
          cutout: "8%",
          offset: 10,
        },
        {
          label: "Valor",
          data: newArr,
          backgroundColor: "rgba(163, 142, 23, 0.8)",
          borderColor: ["rgba(163, 142, 23, 0.8)"],
          borderWidth: 2,
          cutout: "5%",
        },
      ],
    }));

    if (mouth !== undefined) {
      ListNces();
    }
  }, [mouth]);

  const plugins = {
    id: "chart position",
    afterDatasetsDraw(chart: any) {
      const { ctx } = chart;

      ctx.save();

      labels.forEach((datapoint: any, index: any) => {
        const { x, y } = chart.getDatasetMeta(0).data[index].tooltipPosition();

        ctx.font = "bold 10px sans-serif";
        ctx.fillStyle = "white";
        ctx.textAlign = "center";
        ctx.fillText(datapoint, x, y);
      });
    },
  };

  // const mockListNce = [
  //   {
  //     nceId: 0,
  //     severidade: "Grave",
  //     origem: "Processo",
  //     Demérito: "4,8",
  //     Publicacao: "22/08/1999",
  //     Devolucao: "Pendente",
  //     Embalagem: "Aluminio",
  //   },
  //   {
  //     nceId: 1,
  //     severidade: "Critico",
  //     origem: "Processo",
  //     Demérito: "4,9",
  //     Publicacao: "22/08/1998",
  //     Devolucao: "Entregue",
  //     Embalagem: "Pedra",
  //   },
  //   {
  //     nceId: 2,
  //     severidade: "Critico",
  //     origem: "Processo",
  //     Demérito: "4,9",
  //     Publicacao: "22/08/1998",
  //     Devolucao: "Entregue",
  //     Embalagem: "Pedra",
  //   },
  // ];

  const {user} = useAuth()

  React.useEffect(() => {
    if (user.profile === 'usuarioFornecedor') {
      GetSuppliersById(setForn, user.idFabrica)
    } else {
      GetSuppliers(setForn);
    }
    GetSuppliersByUnity(setUnits);
  }, []);

  const Search = React.useCallback(() => {
    GetPQMFAnalatico(setData, 2022, objectForn?.idForn, objectForn?.idUnidade);

    setGrafics(true);
  }, [objectForn]);

  React.useEffect(() => {
    const years = data?.map((item: any) => item.ANO);
    const comercial = data?.map((item: any) => parseInt(item.CM));
    const logistica = data?.map((item: any) => parseInt(item.LG));
    const devolutiva = data?.map((item: any) => parseInt(item.DE));
    const performance = data?.map((item: any) => parseInt(item.PQ));
    const qualificações = data?.map((item: any) => parseInt(item.QA));

    // function randomNumberInterval(a: number, b: number) {
    //   return Math.floor(Math.random() * (b - a + 1)) + a;
    // }

    // const years = randomNumberInterval(0, 9999);
    // const comercial = randomNumberInterval(0, 9999);
    // const logistica = randomNumberInterval(0, 9999);
    // const devolutiva = randomNumberInterval(0, 9999);
    // const performance = randomNumberInterval(0, 9999);
    // const qualificações = randomNumberInterval(0, 9999);

    setUserData({
      labels: years,
      datasets: [
        {
          label: "Comercial",
          type: "bar" as const,
          data: comercial,
          backgroundColor: "rgb(90 86 14 / 80%)",
        },
        {
          label: "Logística",
          type: "bar" as const,
          data: logistica,
          backgroundColor: "rgba(163, 142, 23, 0.8)",
        },
        {
          label: "Devolutiva",
          type: "bar" as const,
          data: devolutiva,
          backgroundColor: "brown",
        },
        {
          label: "Performance",
          type: "bar" as const,
          data: performance,
          backgroundColor: "rgb(177 121 0 / 80%)",
        },
        {
          label: "Qualificação",
          type: "bar" as const,
          data: qualificações,
          backgroundColor: "yellow",
        },
      ],
    });
  }, [data, grafics]);


  return (
    <div style={{ width: "100%" }}>
      <div className="header">
        <h1>PQMF - Analítico</h1>
      </div>
      <div className="info-extract">
        <div style={{ display: "flex", width: "70%" }}>
        
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <label style={{ marginRight: 15, color: "#555555",  width: "100%" }}>
              Fornecedor
            </label>
            <select
              id="statusSelect"
              value={fornItem?.name}
              className="select-defect"
              onChange={(e) => {
                if (e.target.value == '0') {
                  setDisabledButton(true)
                }
                else {
                  setFornItem(e.target.value)
                }
              }}
              style={{ outline: "none", width: "85%" }}
            >
              <option value="0">Selecione...</option>
              {forn.map((item: any) => {
                return (
                  <option value={item.idFornecedor} key={item.idFornecedor}>
                    {item.fornecedorDescricao}
                  </option>
                );
              })}
            </select>
          </div>
        
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label style={{ marginRight: 15, color: "#555555",  width: "100%" }}>Planta</label>
            <select
              id="statusSelect"
              className="select-defect"
              value={objectForn?.location}
              onChange={(e) => {
                e.target.value == "0" ? setDisabledButton(true) :
                  setDisabledButton(false)
                setObjectForn({
                  idForn: fornItem,
                  idUnidade: e.target.value,
                });
              }}
              style={{ outline: "none", width: "85%" }}
            >
              <option value="0">selecione...</option>
              {FilterItemByName &&
                FilterItemByName?.map((item: any) => {
                  return (
                    <>
                      <option value={item.idFornecedorUnidade}>
                        {item.fornecedorUnidadeDescricao}
                      </option>
                    </>
                  );
                })}
            </select>
          </div>
        
        </div>

        <div style={{ display: "flex" }}>
          <button
            className="button-defect-search"
            onClick={() => (objectForn.idUnidade ? Search() : "")}
            disabled={disabledButton}
          >
            <BsSearch style={{ marginRight: 10 }} />
            Pesquisar
          </button>
        </div>
      </div>
      {userData?.datasets[0]?.data !== undefined && (
        <div
          style={{
            display: "flex",
            borderTop: "1px solid rgba(34,36,38,.15)",
            marginTop: 12,
          }}
        >
          <div className="content-left-analitic">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1
                style={{ fontSize: 14, marginBottom: 15 }}
                className="title-graphic"
              >
                <GoGraph
                  color="rgba(163, 142, 23, 0.8)"
                  size={20}
                  style={{ marginRight: 20 }}
                />
                Anual
              </h1>
              <Chart
                ref={chartRef}
                onClick={HandleChangeYear}
                type="bar"
                data={userData}
                options={options}
              />
            </div>
            {mouth && dought?.datasets[0] && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: 46,
                }}
              >
                <h1 style={{ fontSize: 14 }} className="title-graphic">
                  <GoGraph
                    color="rgba(163, 142, 23, 0.8)"
                    size={20}
                    style={{ marginRight: 20 }}
                  />
                  Desdobramento
                </h1>
                <Chart
                  type="doughnut"
                  data={dought}
                  options={optionsDought}
                  plugins={dought.datasets[0].data ? [plugins] : []}
                />
              </div>
            )}
          </div>
          <div className="content-right-analitic" style={{ width: "60%" }}>
            <div className="mounth-graphics">
              {year && dataByMouth.datasets[0].data && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    marginTop: 35,
                    marginLeft: 14,
                  }}
                >
                  <h1
                    style={{ fontSize: 14, marginBottom: 15 }}
                    className="title-graphic"
                  >
                    <GoGraph
                      color="rgba(163, 142, 23, 0.8)"
                      size={20}
                      style={{ marginRight: 20 }}
                    />
                    Mensal
                  </h1>
                  <Chart
                    type="bar"
                    data={dataByMouth}
                    onClick={HandleChangeMouth}
                    ref={chartRefMouth}
                    options={optionsByMouth}
                  />
                </div>
              )}
            </div>

            <div>
              {mouth && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: 40,
                  }}
                >
                  <h1 style={{ fontSize: 14 }} className="title-graphic">
                    <GoGraph
                      color="rgba(163, 142, 23, 0.8)"
                      size={20}
                      style={{ marginRight: 20 }}
                    />
                    Lista de NCEs
                  </h1>

                  <div className="ui container-table">
                    <table className="ui table">
                      <thead>
                        <tr style={{ textAlign: "center" }}>
                          <th>NCE</th>
                          <th>SEVERIDADE</th>
                          <th>ORIGEM</th>
                          <th>DEMÉRITO</th>
                          <th>PUBLICAÇÃO</th>
                          <th>DEVOLUÇÃO DO PLANO</th>
                          <th>EMBALAGEM</th>
                        </tr>
                      </thead>
                      <tbody>
                        {nceList.map((listNce : any) => (
                          <tr style={{ textAlign: "center" }}>
                            <td>{listNce.id_nce}</td>
                            <td>{listNce.severidade}</td>
                            <td>{listNce.origem}</td>
                            <td>{listNce.demerito}</td>
                            <td>{listNce.data_publicacao}</td>
                            <td>{listNce.devolucao_pa}</td>
                            <td>{listNce.tipo_embalagem}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analitico;
