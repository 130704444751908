import styled from "styled-components";

export const Container = styled.div`
  width: auto;
`;

export const StyledInputContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  width: 80%;
`;

export const ContainerSenha = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputSenha = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  input {
    width: 20px;
  }
`;

export const IconVisible = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
`;

export const ContainerRequisitos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 10%;
  }
`;
