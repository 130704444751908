import React from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "./context/auth";
import Login from "./pages/login/login";
import RoutesConfig from "./routes";
import secureLocalStorage from "react-secure-storage";
import ErrorBoundary from "./ui/components/Error";

const App = () => {
  const token: any = secureLocalStorage.getItem("access_token");

  return (
    <>
      <ErrorBoundary>
        <AuthProvider>
          <div>{token ? <RoutesConfig /> : <Login />}</div>
        </AuthProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
