import React, { useState, useEffect, useCallback } from "react";
import { GetUsersResume, GetListaNceRequest } from "../../data/services";
import "../../ui/components/home/home.css";
import { BsFillHouseDoorFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";

import Modal from "../../ui/components/modal/modal";
import useModal from "../../hooks/useModal";

import "./home.css";

import Multisteps from "../../ui/components/itensdoformulario/Multisteps"

import { GetListNce } from "../../data/services";
import { useAuth } from "../../context/auth";
import secureLocalStorage from "react-secure-storage";

interface dataProps {
  NCESalvas: string;
  AguardandoContencao: string;
  AguardandoPlanoAcao: string;
  Aceite: string;
  VerificacaoEficacia: string;
}

const Home = () => {

  const { user } = useAuth()

  const [option, setOption] = React.useState<any>([]);

  const [open, setOpen] = React.useState<any>({
    open: false,
    method: "",
  });

  const type = ("nces")
    ? "Nce"
    : "vazio";

  const [resume, setResume] = useState<dataProps>();

  useEffect(() => {
      CarregarLista()
  }, []);

  async function CarregarLista() {
    await GetListaNceRequest(user.username).then((res: any) => {
      setResume(res.data)
      setOption(res.data)
    })
  }

  const token: any = secureLocalStorage.getItem("access_token");

  if (!token) {
    window.location.href = "/";
  }

  const { isOpen, toggle } = useModal();

  let nce_aceite = 0
  let nce_contencao = 0
  let nce_salva = 0
  let nce_verificacao = 0
  let nce_plano = 0

  const statusNCEItem = option?.find((item: any) => {

    if (item.ETAPA_DESCRICAO === "ABERTURA") { //NCE SALVAS
      nce_salva++
    }
    if (item.ETAPA_DESCRICAO === "CONTENCAO") { //AGUARDANDO CONTENÇÃO
      nce_contencao++
    }
    if (item.ETAPA_DESCRICAO === "VERIFICACAO DE EFICACIA") { //VERIFICAÇÂO DE EFICÁCIA
      nce_verificacao++
    }
    if (item.ETAPA_DESCRICAO === "PLANO DE ACAO") { //AGUARDANDO PLANO DE AÇÃO
      nce_plano++
    }
    if (item.ETAPA_DESCRICAO === "ACEITE") { //ACEITE DE PLANO DE AÇÃO
      nce_aceite++
    }
  })

  return (
    <div>
      <h1 style={{ fontWeight: "400", display: "flex", alignItems: "center" }}>
        <BsFillHouseDoorFill style={{ marginRight: 10 }} />
        Página inicial
      </h1>
      <div className="content-resume">
        <h2
          style={{
            paddingLeft: 20,
            color: "#a38e17",
            fontSize: 26,
            fontWeight: 400,
          }}
        >
          Resumo de Não Conformidades
        </h2>

        <Modal isOpen={isOpen} toggle={toggle}>
          <div className="row">
            <p className="col-md-2 text-center"></p>
            <p className="col-md-8 text-center">
              <strong className="col-md-12 text-center">
                NCE - NÃO CONFORMIDADE DE EMBALAGENS
              </strong>
            </p>
            <p className="col-md-2 text-center">
              <button
                type="button"
                className="btn btn-danger btn-sm"
                data-dismiss="close"
                onClick={toggle}
              >
                <AiOutlineClose />
              </button>
            </p>
          </div>

          <div className="modal-body">
            <div className="container-fluid">
              <Multisteps />
            </div>
          </div>
        </Modal>

        <div className="container-info">
          <div className="box-info">
            <p>NCE SALVAS</p>
            {/* <h3>{resume?.NCESalvas}</h3> */}
            <h3>{nce_salva}</h3>
          </div>
          <div className="box-info">
            <p>AGUARDANDO CONTENÇÃO</p>
            <h3>{nce_contencao}</h3>
            {/* <h3>{resume?.AguardandoContencao}</h3> */}
          </div>
          <div className="box-info">
            <p>AGUARDANDO PLANO DE AÇÃO</p>
            <h3>{nce_plano}</h3>
            {/* <h3>{resume?.AguardandoPlanoAcao}</h3> */}
          </div>
          <div className="box-info">
            <p>ACEITE</p>
            <h3>{nce_aceite}</h3>
            {/* <h3>{resume?.Aceite}</h3> */}
          </div>
          <div className="box-info">
            <p>VERIFICAÇÂO DE EFICÁCIA</p>
            <h3>{nce_verificacao}</h3>
            {/* <h3>{resume?.VerificacaoEficacia}</h3> */}
          </div>
        </div>

        {user.profile !== "usuarioFornecedor" &&
          <button
            className="btn btn-danger"
            onClick={toggle}
            data-toggle="modal"
            data-target="modalNewNCE"
          >Cadastrar nova NCE
          </button>}
      </div>
    </div>
  );
};

export default Home;
