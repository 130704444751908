import React, { useEffect } from "react";
import "../../ui/components/comparativo/comparativo.css";
import { BsSearch } from "react-icons/bs";
import BarChat from "../../ui/components/grafico";
import { IoIosSpeedometer } from "react-icons/io";
import { AiFillDelete } from "react-icons/ai";
import Select from "../../ui/components/comparativo/SelectComparativo/SelectComparativo";
import { MdArrowDropDown } from "react-icons/md";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { GetComparativo, GetUnidade } from "../../data/services";
import { GetYearsArry } from "../../helpers/helpers";
import { useAuth } from "../../context/auth";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const ComparePage = () => {

  const { user: { profile } } = useAuth()

  if (profile === "usuarioFornecedor") window.location.href = "/home";

  const [Forn, setForn] = React.useState<any>([]);

  const [backgroundColor, setBackgroundColor] = React.useState([
    { color: "rgb(90 86 14 / 80%)" },
    { color: "rgb(121 116 42 / 80%)" },
    { color: "rgb(97 106 0 / 80%)" },
    { color: "rgb(177 121 0 / 80%)" },
    { color: "rgb(164 112 0 / 80%)" },
    { color: "brown" },
    { color: "rgb(122 106 0 / 80%)" },
    { color: "rgb(68 61 17 / 80%)" },
    { color: "rgb(114 100 17 / 80%)" },
    { color: "rgba(163, 142, 23, 0.8)" },
  ]);
  const [openSelect, setOpenSelect] = React.useState(false);
  const [data, setData] = React.useState<any>([]);
  const [search, setSearch] = React.useState(false);
  const [year, setYear] = React.useState("0");
  const [unit, setUnit] = React.useState([]);
  const [allForn, setAllForn] = React.useState([]);
  const [anosPesquisa, setAnosPesquisa] = React.useState<number[]>([])

  useEffect(() => {
    handleGrafic();
  }, [data]);

  const [userData, setUserData] = React.useState<any>({
    labels: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
  });

  const handleGrafic = () => {
    const fornecedoresAtivos: any = {};
    const cores = [
      "rgb(90 86 14 / 80%)",
      "rgb(114 100 17 / 80%)",
      "rgb(68 61 17 / 80%)",
      "rgb(122 106 0 / 80%)",
      "brown",
      "rgb(164 112 0 / 80%)",
      "rgb(177 121 0 / 80%)",
      "rgb(97 106 0 / 80%)",
      "rgb(121 116 42 / 80%)",
      "rgb(90 86 14 / 80%)",
    ];

    data.forEach((item: any, index: number) => {
      const fornecedor = item.fornecedor;
      const mes = item.mes_abv;
      const cor = cores[index % cores.length];

      if (!fornecedoresAtivos[fornecedor]) {
        fornecedoresAtivos[fornecedor] = {
          labels: [],
          valores: [],
          cor
        };
      }

      if (!fornecedoresAtivos[fornecedor].labels.includes(mes)) {
        fornecedoresAtivos[fornecedor].labels.push(mes);
        fornecedoresAtivos[fornecedor].valores.push(item.PQMF);
      } else {
        const index = fornecedoresAtivos[fornecedor].labels.indexOf(mes);
        fornecedoresAtivos[fornecedor].valores[index] += item.PQMF;
      }
    });

    const datasets = Object.keys(fornecedoresAtivos).map((fornecedor) => {
      return {
        label: fornecedor,
        data: fornecedoresAtivos[fornecedor]?.valores,
        backgroundColor: fornecedoresAtivos[fornecedor]?.cor,
      };
    });

    setUserData((prev: any) => ({
      ...prev,
      datasets: datasets,
    }));

    setOpenSelect(false);
  };

  React.useEffect(() => {
    GetUnidade(setUnit);
    setAnosPesquisa(GetYearsArry(new Date().getFullYear(), 7));
  }, []);

  const unid = allForn.map((item: any) => {
    return item.idFornecedorUnidade;
  });

  const transformUnit = JSON.stringify(unid)
    .replaceAll("[", "")
    .replaceAll("]", "");


  React.useEffect(() => {
    if(allForn.length > 0 && year != "Selecione"){
      GetComparativo(setData, year, transformUnit);
      setSearch(true);
    }
  }, [allForn, year])

  const fornecedores = unit.map((item: any) => {
    return item;
  });


  return (
    <div>
      <div className="header">
        <h1>PQMF - Comparativo</h1>
      </div>
      <div className="info-compare">
        <div style={{ display: "flex", width: "100%" }}>

          <div
            style={{ display: "flex", flexDirection: "column", width: "30%", marginRight: "20px"}}
          >
            <label style={{ marginRight: 15, color: "#555555", width: "10%" }}>Ano</label>
            <select
              id="statusSelect"
              name=""
              className="select-defect"
              style={{ outline: "none", width: "80%"}}
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="Selecione">Selecione...</option>
              {anosPesquisa.map((item: any) => {
                return (
                  <option value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "40%"
            }}
          >
            <Select
              label="Fornecedores"
              options={fornecedores}
              setSelectedOptions={setAllForn}
              selectedOptions={allForn}              
            />
          </div>


        </div>

        <div style={{ display: "flex", justifyContent: "end"}}>
          <button
            className="button-defect-search"
            onClick={() => {
              GetComparativo(setData, year, transformUnit);
              setSearch(true);
            }}
            disabled={(allForn.length === 0 || year == '0') ? true : false}
          >
            <BsSearch style={{ marginRight: 10 }} />
            Pesquisar
          </button>
        </div>

      </div>
      {userData?.datasets?.[0]?.data !== undefined && allForn.length > 0 && year != "Selecione" && (
        <div
          style={{
            width: "100%",
            borderTop: "1px solid rgba(34,36,38,.15)",
            marginTop: 12,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <h1
              style={{ fontSize: 14, marginBottom: 15 }}
              className="title-graphic"
            >
              <IoIosSpeedometer
                color="rgba(163, 142, 23, 0.8)"
                size={20}
                style={{ marginRight: 20 }}
              />
              Comparação Mensal entre Fornecedores
            </h1>
            <div style={{ width: "99.5%" }}>
              {userData?.datasets?.[0]?.data !== undefined && (
                <BarChat data={userData} options={[]} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComparePage;
