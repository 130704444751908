import "./modalError.css";

const ErrorModal = ({ message, setError }: { message: any; setError: any }) => {

  return (
    <div className="modal" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Erro</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setError("");
                }}
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p>{message}</p>
            </div>
          </div>
        </div>
    </div>
  );
};

export default ErrorModal;
